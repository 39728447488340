<div fxLayout="column" fxLayoutAlign="center" class="containers" >
    <form [formGroup]="FrmGestProveedor">
        <mat-card fxLayout="column">
            <mat-card-title class="Titulo">Buscar Proveedor</mat-card-title><br>
            <mat-card-content fxLayout="column">
                <mat-card-content fxLayout="row">
                    <fieldset class="borderFieldset">
                        <mat-form-field class="Fila">
                            <mat-select placeholder="Buscar por" formControlName="selectBuscar" (selectionChange)="Limpiar()">
                                <mat-option value="1">Número</mat-option>
                                <mat-option value="2">Razón Social</mat-option>                               
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="Fila1">
                            <input matInput aria-label="string" placeholder="Buscar" formControlName="txtFiltro" (keypress)="fnIngNumDocumento($event)">
                        </mat-form-field>
                        <button mat-icon-button class="tooltips_fab" (click)="Buscar()">
                            <mat-icon>search</mat-icon>
                            <span>Buscar</span>
                        </button>
                        <button mat-icon-button class="tooltips_fab" (click)="Refrescar()">
                            <mat-icon class="material-icons-outlined">restore_page</mat-icon>
                            <span>Refrescar</span>
                        </button>
                    </fieldset>            
                </mat-card-content>
            </mat-card-content>
            <mat-card-content fxLayout="column">
                <h3 class="Titulo">Lista de Proveedores</h3>
                <table mat-table [dataSource]="ProveedorTableDataSource">
                    <ng-container matColumnDef="codigo">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <button mat-icon-button class="tooltips_cls" (click)="ResetearClave(element.codigo)">
                                <mat-icon fontSet="material-icons-outlined">replay</mat-icon>
                                <span>Asignar Nueva Clave</span>
                            </button>                            
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="direccion">
                        <th mat-header-cell *matHeaderCellDef>Código</th>
                        <td mat-cell *matCellDef="let element">{{element.codigo}}</td>
                    </ng-container>
                    <ng-container matColumnDef="razonSocial">
                        <th mat-header-cell *matHeaderCellDef>Denominación</th>
                        <td mat-cell *matCellDef="let element">{{element.razonSocial}}</td>
                    </ng-container>
                    <ng-container matColumnDef="dni_ruc">
                        <th mat-header-cell *matHeaderCellDef>Número</th>
                        <td mat-cell *matCellDef="let element">{{element.dni_ruc}}</td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>  
            </mat-card-content>
        </mat-card> 
    </form>      
</div>
<mat-paginator id="paginadorProveedor" class="stylePaginator" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>


