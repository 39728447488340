import { Injectable } from '@angular/core';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import { Functions } from '../helpers/Functions';
import { DetalleFicha } from '../models/DetalleFicha';
import { Ticket } from '../models/Ticket';

import * as pdfMake from "pdfmake/build/pdfmake.min.js";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

@Injectable({
    providedIn: 'root'
})

export class RptPDFTicket {

    constructor(private ObjFunctions: Functions) { }

    /** Diseño de cabecera **/
    setTitle(sede: string, num_ticket: string): any {
        //Margen de la cabecera [left,top,right,bottom]
        let margin = [25, 25, 25, 15];

        let fontSizeTitle=20;
        let fontSizePrint=8;

        return [
            {   margin:margin,
                table: {
                    widths:['*'],
                    headerRows: 1,
                    body:[
                        [
                            {
                                table:{
                                    widths:['33.33%','*','35.33%'],
                                    body:[
                                        [
                                            //Datos de la empresa
                                            { 
                                                text: 'OLEAGINOSAS AMAZONICAS S.A.', 
                                                bold: true, 
                                                fontSize: fontSizePrint
                                            },
                                            //Número del ticket
                                            {
                                                text: num_ticket,
                                                fontSize: fontSizeTitle,
                                                bold: true,
                                                alignment : 'center',
                                                rowSpan:2
                                            },
                                            //Datos de impresión
                                            {
                                                text: [
                                                    { text: 'Impreso: ', bold: true},
                                                    { text: variablesGlobales.usuario.numdoc+' - '+this.ObjFunctions.GetDate()}
                                                ],
                                                fontSize: fontSizePrint,
                                                alignment : 'right'
                                            }
                                        ],
                                        [
                                            {text: 'RUC: 20351410061 '+sede, fontSize: fontSizePrint},
                                            {},
                                            {text: 'COPIA', fontSize: 9, alignment : 'right'},
                                        ]
                                    ]
                                },
                                layout:'noBorders'
                            },
                        ],
                        [{}]
                    ]
                },
                layout: 'headerLineOnly'
            }
        ];
    }

    ShowFicha(nroficha: string): any{
        let fila={};

        if(nroficha!=null && nroficha.trim().length>0){
            fila={ text: 'FICHA NRO: '+nroficha, style: 'tableContent'};
        }

        return fila;
    }

    /** Método para crear el encabezado de tabla **/
    createTableHeader(obj: Ticket, fontSizeTitle: number): any {
        let pageHeader:any = { 
            fila_0: {
                col_1:{
                    text: [
                        { text: 'EVALUACIÓN\t\t\t\t', bold: true, fontSize: fontSizeTitle},
                        this.ShowFicha(obj.nroficha!)
                        
                    ],
                    colSpan: 5,
                    border: [false, false, false, true],
                    margin: [-5,0,0,0]
                },
                col_2:{},
                col_3:{},
                col_4:{},
                col_5:{}
            },
            fila_1: {}
        };

        let subcabecera:any=["Controlador: "+((obj.objSup!=null)?obj.objSup?.nombre:""),"VERDE","SOBRE MADURO","ESCOBAJO","PEDÚNCULO"];

        subcabecera.forEach((attribute:string, i:number) => {
            pageHeader.fila_1['col_' + (+i + 1)] = { text: attribute, border: [false, false, false, true] };

            if(i>0){
                pageHeader.fila_1['col_' + (+i + 1)].style='tableHeader'; 
            }
            else{
                pageHeader.fila_1['col_' + (+i + 1)].style= 'tableContent';  
            }
        });

        return pageHeader;
    }

    /** Método para crear el contenido de tabla **/
    createTableBody(headers: any, data: any[], castigo: number): any {
        const body = [];
        let count = 0;

        for (const key in headers) {
            const row = [];

            for (const headerKey in headers[key]) {
                row.push(headers[key][headerKey]);

                if(key=="fila_0"){
                    count++;
                }
            }

            body.push(row);
        }

        if(castigo>0){
            data.forEach((item:any) => {
                const row = [];
    
                for (const key in item) {
                    if(key!="descripcion"){
                        row.push({ text: item[key], style: 'tableContent', alignment: 'center', border: [false, false, false, false] });
                    }
                    else{
                        row.push({ text: item[key], style: 'tableContent', alignment: 'right', border: [false, false, false, false] });
                    }   
                }
    
                body.push(row);
            });
        }
        else{
            const rows:any = { fila_0: {} };

            for(let i=0; i<count; i++){
                rows.fila_0['col_' + (+i + 1)] = { text: ' ', style: 'tableContent', border: [false, false, false, false] };
            }

            for (var key in rows) {
                if (rows.hasOwnProperty(key))
                {
                    var element = rows[key];
                    var row = new Array();

                    for(let i=0; i<count; i++){
                       row.push(element['col_' + (+i + 1)]) ;
                    }

                    body.push(row);
                }
            }
        }

        return body;
    }

    ShowOrganico(codbien:number, fontSize:number): any{
        let body={};

        if(codbien==12891 || codbien==12892){
            body={
                margin: [0, 5, 0, 0],
                alignment: 'justify',
                columns: [
                    {
                        text: 'ORGANICO', bold: true, italics: true, decoration:'underline', fontSize: fontSize
                    }
                ]
            };
        }

        return body;
    }

    createTableProducto(obj: Ticket, fontSizeTitle: number, fontSizeBody: number): any {
        let pageHeader:any = { 
            fila_0: {
                col_1:{ text: 'DATOS DE PRODUCTO', bold: true, colSpan: 3, fontSize: fontSizeTitle },
                col_2:{},
                col_3:{}
            },
            fila_1: {
                col_1:{ text: 'Proveedor:', fontSize: fontSizeBody, margin: [5,5,0,0] },
                col_2:{ text: obj.prv?.dni_ruc+' '+obj.prv?.razonSocial, fontSize: fontSizeBody, margin: [0,5,0,0] },
                col_3:{ text: 'Racimos: '+((obj.nroRac!>0)?obj.nroRac:""), fontSize: fontSizeBody, margin: [0,5,0,0] }
            },
            fila_2: {
                col_1:{ text: 'Origen:', fontSize: fontSizeBody, margin: [5,0,0,0] },
                col_2:{ text: obj.objComite?.descripcion+' - '+obj.sect?.descripcion, fontSize: fontSizeBody, colSpan:2 },
                col_3:{}
            }
        };

        if(obj.prd?.codBien==12891 || obj.prd?.codBien==12892){
            pageHeader['fila_3']={};
            pageHeader.fila_3['col_1'] = { text: 'Código:', fontSize: fontSizeBody, margin: [5,0,0,0] };
            pageHeader.fila_3['col_2'] = { text: obj.codigo, fontSize: fontSizeBody, colSpan:2 };
            pageHeader.fila_3['col_3'] = {};
        }

        const body = [];

        for (const key in pageHeader) {
            const row = [];

            for (const headerKey in pageHeader[key]) {
                row.push(pageHeader[key][headerKey]);
            }

            body.push(row);
        }

        return body;
    }

    /** Definición del documento PDF **/
    getDocDefinition(obj: Ticket, orientation: string, pageSize: string): any {
        let pageMargin=[25, 65, 25, 55];
        let fontSizeTitle=11;
        let fontSizeBody=9;
        let fontSizeResult=12;
        let headerFontTable=9;
        let bodyFontTable=9;
    
        const docDefinition = {
            pageOrientation: orientation,
            pageSize,
            pageMargins: pageMargin,
            header: this.setTitle(obj.objSede?.sede!, obj.numero!),
            footer: (currentPage: number, pageCount: number) => {
                return { text: 'Página ' + currentPage.toString() + ' de ' + pageCount, alignment: 'center', margin: [0, 30, 0, 0], fontSize: 8 };
            },
            content: [
                {
                    alignment: 'justify',
                    columns: [
                        {
                            text: 'Propietario: '+obj.emp?.razonSocial, bold: true, fontSize: fontSizeBody
                        }
                    ]
                },
                this.ShowOrganico(obj.prd?.codBien!, fontSizeBody),
                {   margin: [0, 8, 0, 0],
                    table: {
                        widths: ['13%','*', '8%','42%'],
                        body: [
                            [
                                { text: 'MOVIMIENTO:', bold: true, fontSize: fontSizeBody},
                                { text: obj.movimiento, fontSize: fontSizeBody},
                                { text: 'Producto:', bold: true, fontSize: fontSizeBody},
                                { text: obj.prd?.nombre, fontSize: fontSizeBody},
                            ],
                            [
                                { text: 'OBSERVACIÓN:', bold: true, fontSize: fontSizeBody},
                                { text: obj.observacion, fontSize: fontSizeBody, colSpan:3},
                                {},
                                {},
                            ],
                        ]
                    },
                    layout: 'noBorders'
                },
                {
                    margin: [0, 15, 0, 0],
                    table: {
                        widths: ['9%','41%', '10%','*','12%'],
                        headerRows: 1,
                        body: [
                            [
                                {text: 'DATOS DE TRANSPORTE', bold: true, colSpan: 5, fontSize: fontSizeTitle}, 
                                {}, 
                                {},
                                {},
                                {}
                            ],
                            [
                                { text: 'Vehículo:', fontSize: fontSizeBody, margin: [5,5,0,0]},
                                { text: obj.objveh?.placa+' '+obj.objveh?.marca, fontSize: fontSizeBody, margin: [0,5,0,0]},
                                { text: 'Conductor:', fontSize: fontSizeBody, margin: [0,5,0,0]},
                                { 
                                    text: obj.objcon?.licencia+' '+obj.objcon?.nombre+' '+obj.objcon?.paterno+' '+obj.objcon?.materno, 
                                    fontSize: fontSizeBody,
                                    margin: [0,5,0,0], 
                                    colSpan: 2
                                },
                                {}
                            ],
                            [
                                { text: 'Empresa:', fontSize: fontSizeBody, margin: [5,0,0,0]},
                                { text: obj.tra?.dni_ruc+' '+obj.tra?.razonSocial, fontSize: fontSizeBody, colSpan:3},
                                {},
                                {},
                                {text: 'Flete: '+this.ObjFunctions.formatNumberES(obj.flete!,2), fontSize: fontSizeBody, bold:true, alignment: 'right'}
                            ],
                        ]
                    },
                    layout: 'headerLineOnly'
                },
                /*{
                    margin: [0, 5, 0, 0],
                    table: {
                        widths: ['10%', '*', '20%'],
                        headerRows: 1,
                        body: [
                            [
                                {text: 'DATOS DE PRODUCTO', bold: true, colSpan: 3, fontSize: fontSizeTitle}, 
                                {},
                                {}
                            ],
                            [
                                { text: 'Proveedor:', fontSize: fontSizeBody, margin: [5,5,0,0]},
                                { text: obj.prv?.dni_ruc+' '+obj.prv?.razonSocial, fontSize: fontSizeBody, margin: [0,5,0,0]},
                                { text: 'Racimos: '+((obj.nroRac!>0)?obj.nroRac:""), fontSize: fontSizeBody, margin: [0,5,0,0]}
                            ],
                            [
                                { text: 'Origen:', fontSize: fontSizeBody, margin: [5,0,0,0]},
                                { text: obj.objComite?.descripcion+' - '+obj.sect?.descripcion, fontSize: fontSizeBody, colSpan:2},
                                {}
                            ]
                        ]
                    },
                    layout: 'headerLineOnly'
                },*/
                {
                    margin: [0, 5, 0, 0],
                    table: {
                        widths: ['10%', '*', '20%'],
                        headerRows: 1,
                        body: this.createTableProducto(obj, fontSizeTitle, fontSizeBody)
                    },
                    layout: 'headerLineOnly'
                },               
                {
                    margin: [0, 5, 0, 0],
                    table: {
                        widths: ['45%', '13.75%', '13.75%', '13.75%', '13.75%'],
                        body: this.createTableBody(this.createTableHeader(obj, fontSizeTitle), this.mapData(obj.detalleFicha!), obj.castigo!)
                    }
                },
                {
                    table: {
                        widths: ['*', '11%'],
                        body: [
                            [
                                {
                                    text: [
                                        { text: 'Entrada: ', bold: true},
                                        { text: obj.usufechoraentrada}
                                    ],
                                    fontSize: fontSizeBody,
                                    border: [false, true, false, false],
                                    alignment: 'right'
                                },
                                { text: this.ObjFunctions.formatNumberES(obj.pesoEntrada!), fontSize: fontSizeResult, border: [false, true, false, false], alignment: 'right'}                           
                            ],
                            [
                                {
                                    text: [
                                        { text: 'Salida: ', bold: true},
                                        { text: obj.usufechoraSalida}
                                    ],
                                    fontSize: fontSizeBody,
                                    border: [false, false, false, false],
                                    alignment: 'right'
                                },
                                { text: this.ObjFunctions.formatNumberES(obj.pesoSalida!), fontSize: fontSizeResult, border: [false, false, false, false], alignment: 'right'}  
                            ]
                        ]
                    }
                },
                {  
                    columns: [
                        { width: '*', text: '' },
                        {
                            width: 'auto',
                            table: {
                                body: [
                                    [
                                        {
                                            table: {
                                                widths: ['*','*'],
                                                headerRows: 1,
                                                body: [
                                                    [{text: 'Total Traslado:', fontSize: fontSizeBody, bold: true, alignment: 'right', border: [false, true, false, false]},
                                                        {text: this.ObjFunctions.formatNumberES(obj.pesoEntrada! - obj.pesoSalida!), fontSize: fontSizeResult, alignment: 'right', width: '11%', border: [false, true, false, false]}],
                                                    [{text: 'Castigo:', fontSize: fontSizeBody, bold: true, alignment: 'right', border: [false, false, false, true]},
                                                        {text: this.ObjFunctions.formatNumberES(obj.castigo!), fontSize: fontSizeResult, alignment: 'right', width: '11%', border: [false, false, false, true]}],
                                                    [{text: 'Neto:', fontSize: fontSizeResult, bold: true, alignment: 'right', border: [false, false, false, false]},
                                                        {text: this.ObjFunctions.formatNumberES(obj.pesoEntrada! - obj.pesoSalida! - obj.castigo!), fontSize: fontSizeResult, bold: true, alignment: 'right', width: '11%', border: [false, false, false, false]}]
                                                ]
                                            }
                                        }
                                    ]
                                ]
                            },
                            layout: 'noBorders'
                        }
                    ]
                },
                /*{
                    //margin: [0, 70, 0, 0],
                    table: {
                        widths: ['*', '*'],
                        body: [
                            [
                                {
                                    columns: [
                                        { width: '*', text: '' },
                                        {
                                            width: 'auto',
                                            table: {
                                                body: [
                                                    [{
                                                        canvas: [
                                                            {
                                                                type: 'line',
                                                                x1: 0, y1: 0,
                                                                x2: 200, y2: 0,
                                                                lineWidth: 1,
                                                                dash: {length: 2, space: 2}
                                                            }
                                                        ]
                                                    }],
                                                    /*[{text: 'DELGADO MOREY CESAR', fontSize: fontSizeBody, bold: true, alignment: 'center'}],
                                                    [{text: 'OFICPRINCIP', fontSize: fontSizeBody, bold: true, alignment: 'center'}],
                                                    [{text: 'Oleaginosas Amazonicas SA', fontSize: fontSizeBody, bold: true, alignment: 'center'}]*/
                                                    /*[{text: 'Consulta Web', fontSize: 8, alignment: 'center'}]
                                                ]
                                            },
                                            layout: 'noBorders'
                                        },
                                        { width: '*', text: '' },
                                    ]
                                },*/
                                /*{
                                    columns: [
                                        { width: '*', text: '' },
                                        {
                                            width: 'auto',
                                            table: {
                                                body: [
                                                    [{
                                                        canvas: [
                                                            {
                                                                type: 'line',
                                                                x1: 0, y1: 0,
                                                                x2: 240, y2: 0,
                                                                lineWidth: 1,
                                                                dash: {length: 5, space: 2}
                                                            }
                                                        ]
                                                    }],
                                                    [{text: 'CAYO ALBERTO CORNEJO QUIÑONES', fontSize: fontSizeBody, bold: true, alignment: 'center'}],
                                                    [{text: 'Conductor', fontSize: fontSizeBody, bold: true, alignment: 'center'}]
                                                ]
                                            },
                                            layout: 'noBorders'
                                        },
                                        { width: '*', text: '' },
                                    ]
                                }  */                   
                            /*]
                        ]
                    },
                    layout: 'noBorders'
                }*/
            ],
            styles: {
                tableHeader: {
                    fontSize: headerFontTable,
                    bold: true,
                    alignment: 'center'
                },
                tableContent: {
                    fontSize: bodyFontTable
                }
            }
        };

        return docDefinition;
    }

    generatePdf(docDefinition: any, num_ticket: string): void {
        const pdfObject = pdfMake.createPdf(docDefinition);
        //pdfObject.download();//Descargar
        //pdfObject.download("TK"+num_ticket+".pdf");//Descargar con un nombre establecido
        pdfObject.open();//Abrir en otra pestaña
        //pdfObject.open({}, window);//Abrir en la misma ventana
    }

    getPdfData(obj: Ticket): void {
        this.generatePdf(this.getDocDefinition(obj, 'portrait', 'A4'), obj.numero!);
    }

    mapData(data: DetalleFicha[]): any {
        return data.map((item) => {
            return {
                descripcion:item.descripcion,
                verde: item.rverde, 
                sobremaduro:item.rmaduro, 
                escobajo:item.escobajo, 
                pedunculo:item.pedunculo
            };
        });
    }
}