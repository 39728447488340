<div class="container-fluid py-3">
  <form [formGroup]="fgLogin" >     
    <div fxLayout="column" fxLayoutAlign="space-around center" class="containers">
      <mat-card fxLayout="column">   
        <div fxLayoutAlign="center"><img src="assets/img/Olamsa.png" style="width:300px !important;"></div><br>   
        <mat-card-content fxLayout="column">                       
          <mat-form-field >
            <input matInput aria-label="string" placeholder="Nombre de Usuario" formControlName="Login" autofocus>
          </mat-form-field>
          <mat-form-field>
            <input matInput aria-label="string" type="password" placeholder="Clave" formControlName="Clave" >
          </mat-form-field>  
        </mat-card-content>                      
        <!--<mat-label>Marque la casilla de seguridad:</mat-label>
        <re-captcha formControlName="recaptchaReactive" (resolved)="resolved($event)"></re-captcha>-->      
        <mat-card-actions fxLayoutAlign="center">
          <button mat-button mat-raised-button class="btnStyle" [disabled]="fgLogin.invalid" (click)="ValidarLogin()">
            Inicio de Sesión
          </button>  
          <br/>
        </mat-card-actions>      
      </mat-card> 
    </div>
  </form>
</div>


