import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AutenticaService } from 'src/app/@core/services/AutenticaService';
import { ProveedorService } from 'src/app/@core/services/ProveedorService';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-actualiza-clave',
  templateUrl: './actualiza-clave.component.html',
  styleUrls: ['./actualiza-clave.component.scss']
})
export class ActualizaClaveComponent implements OnInit {

  FrmResetearPassword!: FormGroup;

  @ViewChild('autosize') autosize!: CdkTextareaAutosize;

  constructor(private _formBuilder: FormBuilder,
              private router: Router,
              private ObjAutenticaService: AutenticaService,
              private ObjProveedorService: ProveedorService) { }

  ngOnInit(): void {
    this.FrmResetearPassword=this._formBuilder.group({
      usuario:[variablesGlobales.usuario.numdoc.trim()+" - "+variablesGlobales.usuario.nombre],
      clave_old:[null,Validators.compose([Validators.required,Validators.maxLength(20)])],
      clave:[null,Validators.compose([Validators.required,Validators.maxLength(20)])],
      clave_temp:[null,Validators.compose([Validators.required,Validators.maxLength(20)])],
    });
  }

  alertMsg(msg:string){
    Swal.fire({
      html:msg,
      icon:'info'
    })
  }

  ValidarCampos(){
    if (this.FrmResetearPassword.controls["clave_old"].value === null || 
      this.FrmResetearPassword.controls["clave_old"].value.length === 0 || 
      /^\s+$/.test(this.FrmResetearPassword.controls["clave_old"].value)) {
        this.alertMsg('Ingrese clave actual');
        return false;
    }

    if (this.FrmResetearPassword.controls["clave"].value === null || 
      this.FrmResetearPassword.controls["clave"].value.length === 0 || 
      /^\s+$/.test(this.FrmResetearPassword.controls["clave"].value)) {
        this.alertMsg('Ingrese clave nueva');
        return false;
    }

    if (this.FrmResetearPassword.controls["clave_temp"].value === null || 
      this.FrmResetearPassword.controls["clave_temp"].value.length === 0 || 
      /^\s+$/.test(this.FrmResetearPassword.controls["clave_temp"].value)) {
        this.alertMsg('Confirme clave');
        return false;
    }

    if(this.FrmResetearPassword.controls["clave_old"].value===this.FrmResetearPassword.controls["clave"].value ){
      this.alertMsg('Clave nueva no puede ser igual a clave actual');
      return false;
    }

    if(this.FrmResetearPassword.value.clave!==this.FrmResetearPassword.value.clave_temp){
      this.alertMsg('Confirmar clave no coincide con clave nueva');
      return false;
    }

    return true;
  }

  Guardar(){
    if(this.ValidarCampos()){
      this.ObjAutenticaService.ValidarApp()
        .subscribe(
          data => { 
            if(data.isSuccess){
              if(data.tx_token.length>0){
                variablesGlobales.token=data.tx_token;

                this.ObjProveedorService.EmpresaCambiarClave(this.FrmResetearPassword.controls["clave"].value,this.FrmResetearPassword.controls["clave_old"].value).pipe()
                  .subscribe(
                    data => {       
                      if (data.isSuccess){
                        if(data.tx_Mensaje.substring(0,1)==="1"){
                          Swal.fire({
                            title: data.tx_Mensaje.substring(1,data.tx_Mensaje.length),
                            icon: 'success'});

                          this.Limpiar();
                        }
                        else{
                          Swal.fire({
                            title: data.tx_Mensaje.substring(1,data.tx_Mensaje.length),
                            icon: 'warning'});
                        }
                      }
                      else{
                        Swal.fire({
                          title: 'Error al procesar en BD',
                          icon: 'warning'}); 
                      } 
                    },
                    error => {
                      console.log(error);
                      Swal.fire({
                        title:'El servicio solicitado no se encuentra disponible',
                        icon: 'error',
                      });
                    }
                  );
              }
              else{
                  variablesGlobales.token='';
                  variablesGlobales.usuario.codusu=0;
                  this.router.navigate(['login']);
              }
            }
          },
          error => {
            console.log(error);
            variablesGlobales.token='';
            variablesGlobales.usuario.codusu=0;
            this.router.navigate(['login']);
          }   
        );
    }
  }

  Limpiar(){
    this.FrmResetearPassword.controls["clave_old"].setValue(null);
    this.FrmResetearPassword.controls["clave"].setValue(null);
    this.FrmResetearPassword.controls["clave_temp"].setValue(null);
  }
}
