import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuracion } from './config';

@Injectable({
    providedIn: 'root'
})

export class BoletaService {
    public url:string = Configuracion.url1 + 'Boleta/';
    
    constructor(private _http: HttpClient) { }

    Obtener(id: number){
        let bodyServicio = {"codigo":id};

        return this._http.post<any>(this.url + 'DocBoleta', bodyServicio);
    }
}