<div fxLayout="column" fxLayoutAlign="center center" class="div_contenedor">    
    <mat-card fxLayout="column">
        <mat-card-title class="Titulo">Lista de Reportes</mat-card-title>   
        <form [formGroup]="FrmConsultarRecord" >           
            <mat-card-content fxLayout="column">
                <mat-form-field class="Fila">
                    <mat-select placeholder="Seleccione un reporte" formControlName="selectReporte" (selectionChange)="MostrarParametros($event.value)">
                        <mat-option value="27">Record de Entregas</mat-option>                       
                    </mat-select>
                </mat-form-field>  
            </mat-card-content>  
            <mat-card-content fxLayout="row" *ngIf="opc==27">
                <mat-form-field class="Fecha">
                    <input matInput [matDatepicker]="FecIni" placeholder="Desde" formControlName="FecIni">
                    <mat-datepicker-toggle matSuffix [for]="FecIni"></mat-datepicker-toggle>
                    <mat-datepicker #FecIni></mat-datepicker>
                </mat-form-field> 
                <mat-form-field class="Fecha">
                        <input matInput [matDatepicker]="FecEnd" placeholder="Hasta" formControlName="FecEnd">
                        <mat-datepicker-toggle matSuffix [for]="FecEnd" ></mat-datepicker-toggle>
                        <mat-datepicker #FecEnd></mat-datepicker>
                </mat-form-field>
            </mat-card-content> 
        </form>                 
       <mat-card-actions style="margin:0">
            <button mat-button mat-raised-button class="btnProcesar" (click)="Procesar()">Procesar</button> 
       </mat-card-actions>
   </mat-card> 
</div>
