import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { Functions } from 'src/app/@core/helpers/Functions';
import { ResumenTicketProveedor } from 'src/app/@core/models/ResumenTicketProveedor';
import { RptPDFRecordTicket } from 'src/app/@core/reports/RptPDFRecordTicket';
import { AutenticaService } from 'src/app/@core/services/AutenticaService';
import { ReporteService } from 'src/app/@core/services/ReporteService';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import Swal from 'sweetalert2';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

@Component({
  selector: 'app-record-entrega',
  templateUrl: './record-entrega.component.html',
  styleUrls: ['./record-entrega.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class RecordEntregaComponent implements OnInit {

  FrmConsultarRecord!: FormGroup;
  ObjResumenTicketProveedor!:ResumenTicketProveedor;
  opc:number=0;

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  constructor(private _formBuilder: FormBuilder,
              private router: Router,
              private ObjAutenticaService: AutenticaService,
              private ObjFunctions: Functions,
              private ObjReporteService: ReporteService,
              private pdfRT: RptPDFRecordTicket) { }

  ngOnInit(): void {
    this.FrmConsultarRecord=this._formBuilder.group({
      selectReporte: ["27"],
      FecIni: [new Date()],
      FecEnd: [new Date()]
    });

    this.MostrarParametros(this.FrmConsultarRecord.controls["selectReporte"].value);
  }

  MostrarParametros(value: string){
    this.opc=parseInt(value);
  }

  alertMsg(msg:string){
    Swal.fire({
      html:msg,
      icon:'info'
    })
  }

  ValidarFechas(){
    if (this.FrmConsultarRecord.controls["FecIni"].value === null || 
      this.FrmConsultarRecord.controls["FecIni"].value.length === 0 || 
      /^\s+$/.test(this.FrmConsultarRecord.controls["FecIni"].value)) {
        this.alertMsg('Ingrese Fecha de Inicio o establezca el formato correcto');
        return false;
    }

    if (this.FrmConsultarRecord.controls["FecEnd"].value === null || 
      this.FrmConsultarRecord.controls["FecEnd"].value.length === 0 || 
      /^\s+$/.test(this.FrmConsultarRecord.controls["FecEnd"].value)) {
        this.alertMsg('Ingrese Fecha Fin o establezca el formato correcto');
        return false;
    }

    var valfechaini = new Date(this.FrmConsultarRecord.controls["FecIni"].value);
    var valfechafin = new Date(this.FrmConsultarRecord.controls["FecEnd"].value);

    if (!this.ObjFunctions.ValidateDateRange(valfechaini,valfechafin)) {
      this.alertMsg('Fecha Fin tiene que ser mayor que Fecha de Inicio.');
      return false;
    }

    return true;
  }

  Procesar(){
    if(this.opc==27){
      if(this.ValidarFechas()){
        this.ObjAutenticaService.ValidarApp()
          .subscribe(
            data => { 
              if(data.isSuccess){
                if(data.tx_token.length>0){
                  variablesGlobales.token=data.tx_token;
  
                  this.ObjReporteService.Obtener(this.opc,this.convertFechaDate(new Date(this.FrmConsultarRecord.controls["FecIni"].value)),
                                                this.convertFechaDate(new Date(this.FrmConsultarRecord.controls["FecEnd"].value)))
                    .subscribe(
                      data => {
                        if (data.isSuccess){
                          this.ObjResumenTicketProveedor=data;
  
                          this.pdfRT.getPdfData(this.ObjResumenTicketProveedor, 
                            this.convertFechaDate(new Date(this.FrmConsultarRecord.controls["FecIni"].value)),
                            this.convertFechaDate(new Date(this.FrmConsultarRecord.controls["FecEnd"].value))
                          );
                        }
                        else{
                          Swal.fire({
                            title: 'Error al procesar en BD',
                            icon: 'warning'}); 
                        }                                         
                      },
                      error => {
                        console.log(error);
                        Swal.fire({
                          title:'El servicio solicitado no se encuentra disponible',
                          icon: 'error',
                        });
                      }
                    );             
                }
                else{
                    variablesGlobales.token='';
                    variablesGlobales.usuario.codusu=0;
                    this.router.navigate(['login']);
                }
              }
            },
            error => {
              console.log(error);
              variablesGlobales.token='';
              variablesGlobales.usuario.codusu=0;
              this.router.navigate(['login']);
            }   
          );
      }
    }
  }

  convertFechaDate(fecha: Date) {
    const dia: string = fecha.getDate().toString().padStart(2, '0');
    const mes: string = (fecha.getMonth() + 1).toString().padStart(2, '0');
    const anio: string = fecha.getFullYear().toString();
    return dia + '/' + mes + '/' + anio; 
  }
}
