import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuracion } from './config';
import { variablesGlobales } from 'src/app/Util/variableGlobal';

@Injectable({
    providedIn: 'root'
})

export class DocTKBLPTService {
    public url:string = Configuracion.url1 + 'DocTKBLPT/';
    
    constructor(private _http: HttpClient) { }

    Listar(opc: number, dato: string, fechaini: string, fechafin:string){
        let bodyServicio = {"nU_CODIGO_USUARIO":variablesGlobales.usuario.codusu,
                            "nU_OPCION_BUSQUEDA":opc,
                            "tX_DATO_BUSQUEDA":dato,
                            "fE_FECHA_INICIO_BUSQUEDA":fechaini,
                            "fE_FECHA_TERMINO_BUSQUEDA":fechafin};

        return this._http.post<any>(this.url + 'Listar', bodyServicio);
    }
}