import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class ImageToBase64 {
    
    constructor() { }

    //Convert image url in assets to b64
    getBase64Image(url: string): Promise<any> {
        return new Promise((resolve) => {
        const image = new Image();
        const outputFormat = 'image/png';
        image.crossOrigin = 'Anonymous';
        image.onload = () => {
            let canvas = <HTMLCanvasElement>document.createElement('CANVAS');
            const context = canvas.getContext('2d');
            let dataURL;
            canvas.height = image.height;
            canvas.width = image.width;
            context!.drawImage(image, 0, 0);
            dataURL = canvas.toDataURL(outputFormat);
            resolve(dataURL);
        };
        image.src = url;
        });
    
    }
}