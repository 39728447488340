import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DocTKBLPT } from 'src/app/@core/models/DocTKBLPT';
import { AutenticaService } from 'src/app/@core/services/AutenticaService';
import { DocTKBLPTService } from 'src/app/@core/services/DocTKBLPTService';
import { RptPDFTicket } from 'src/app/@core/reports/RptPDFTicket';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import Swal from 'sweetalert2';
import { TicketService } from 'src/app/@core/services/TicketService';
import { Ticket } from 'src/app/@core/models/Ticket';
import { RptPDFBoleta } from 'src/app/@core/reports/RptPDFBoleta';
import { Functions } from 'src/app/@core/helpers/Functions';
import { BoletaService } from 'src/app/@core/services/BoletaService';
import { Boleta } from 'src/app/@core/models/Boleta';
import { DeudaService } from 'src/app/@core/services/DeudaService';
import { Deuda } from 'src/app/@core/models/Deuda';
import { RptPDFDeuda } from 'src/app/@core/reports/RptPDFDeuda';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

@Component({
  selector: 'app-consultar-documento',
  templateUrl: './consultar-documento.component.html',
  styleUrls: ['./consultar-documento.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class ConsultarDocumentoComponent implements OnInit {

  FrmConsultarDoc!: FormGroup;

  codigo:number=0;
  Filtro:any[]=[];
  isVisiblePlanta:boolean=false;
  isVisiblePlaca:boolean=false;
  isVisibleTransportista:boolean=false;
  isVisibleNro_ts:boolean=false;
  isVisibleProveedor:boolean=false;
  isVisibleConductor:boolean=false;
  isVisiblePeso:boolean=false;
  isVisibleImporte:boolean=false;
  isVisibleSaldo:boolean=false;
  ObjTicket!:Ticket;
  ObjBoleta!:Boleta;
  ObjDeuda!:Deuda;

  DocTKBLPT: DocTKBLPT[]=[];
  columns:any[]=[];
  displayedColumns: string[] = [];

  DocTKBLPTTableDataSource = new MatTableDataSource(this.DocTKBLPT);

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  constructor(private _formBuilder: FormBuilder,
              private router: Router,
              private ObjAutenticaService: AutenticaService,
              private ObjDocTKBLPTService: DocTKBLPTService,
              private ObjFunctions: Functions,
              private ObjTicketService: TicketService,
              private ObjBoletaService: BoletaService,
              private ObjDeudaService: DeudaService,
              private pdfTK: RptPDFTicket,
              private pdfBL: RptPDFBoleta,
              private pdfPT: RptPDFDeuda) { }

  ngOnInit(): void {
    this.FrmConsultarDoc=this._formBuilder.group({
      selectTipoDoc:['1'],
      FecIni: [new Date()],
      FecEnd: [new Date()],
      selectBuscar:[null],
      txtFiltro:[''],
    });

    this.fnListarFiltro(this.FrmConsultarDoc.controls["selectTipoDoc"].value);
  }

  ValidarObtenerLista(){
    var result=true;

    if (this.FrmConsultarDoc.controls["FecIni"].value === null || 
      this.FrmConsultarDoc.controls["FecIni"].value.length === 0 || 
      /^\s+$/.test(this.FrmConsultarDoc.controls["FecIni"].value)) {
        result=false;
    }

    if (this.FrmConsultarDoc.controls["FecEnd"].value === null || 
      this.FrmConsultarDoc.controls["FecEnd"].value.length === 0 || 
      /^\s+$/.test(this.FrmConsultarDoc.controls["FecEnd"].value)) {
        result=false;
    }

    var valfechaini = new Date(this.FrmConsultarDoc.controls["FecIni"].value);
    var valfechafin = new Date(this.FrmConsultarDoc.controls["FecEnd"].value);

    if (!this.ObjFunctions.ValidateDateRange(valfechaini,valfechafin)) {
      result=false;
    }

    return result;
  }

  fnListarFiltro(opc:string){
    var data:any=[];
    if(opc==="1"){
      this.Filtro=[
        {codigo:0,descripcion:'Número'},
        {codigo:1,descripcion:'Transportista'},
        {codigo:2,descripcion:'Vehículo'},
        {codigo:3,descripcion:'Conductor'}
      ];

      data=[
        {idcolumna: "codigo", cabecera: "", class: "tk_codigo"},
        {idcolumna: "planta", cabecera: "Planta", class: "tk_planta"},
        {idcolumna: "fecha", cabecera: "Fecha", class: "tk_fecha"},
        {idcolumna: "numero", cabecera: "Número", class: "tk_numero"},
        {idcolumna: "placa", cabecera: "Placa", class: "tk_placa"},
        {idcolumna: "transportista", cabecera: "Transportista", class: "tk_transportista"},
        {idcolumna: "observacion", cabecera: "Observación", class: "tk_observacion"},
        {idcolumna: "conductor", cabecera: "Conductor", class:  "tk_conductor"},
        {idcolumna: "peso", cabecera: "Peso", class: "tk_peso"}
      ];
    }
    else if(opc==="2"){
      this.Filtro=[
        {codigo:10,descripcion:'Número'},
        {codigo:11,descripcion:'Nro. Medio de Pago'}
      ];

      data=[
        {idcolumna: "codigo", cabecera: "", class: "bl_codigo"},
        {idcolumna: "planta", cabecera: "Planta", class: "bl_planta"},
        {idcolumna: "fecha", cabecera: "Fecha", class: "bl_fecha"},
        {idcolumna: "numero", cabecera: "Número", class:"bl_numero"},
        {idcolumna: "nro_ts", cabecera: "Nro. Comprobante", class: "bl_nro_ts"},
        {idcolumna: "proveedor", cabecera: "Proveedor", class: "bl_proveedor"},
        {idcolumna: "observacion", cabecera: "Observación", class: "bl_observacion"}
      ];
    }
    else if(opc==="3"){
      this.Filtro=[
        {codigo:20,descripcion:'Número'},
        {codigo:21,descripcion:'Nro. Medio de Pago'}
      ];

      data=[
        {idcolumna: "codigo", cabecera: "", class: "pt_codigo"},
        {idcolumna: "fecha", cabecera: "Fecha", class: "pt_fecha"},
        {idcolumna: "numero", cabecera: "Número", class: "pt_numero"},
        {idcolumna: "nro_ts", cabecera: "Nro. Comprobante", class: "pt_nro_ts"},
        {idcolumna: "observacion", cabecera: "Observación", class: "pt_observacion"},
        {idcolumna: "importe", cabecera: "Importe", class: "pt_importe"},
        {idcolumna: "saldo", cabecera: "Deuda", class: "pt_saldo"}
      ];
    }

    this.FrmConsultarDoc.controls["selectBuscar"].setValue(this.Filtro[0].codigo);
    this.Limpiar();

    this.columns = data.map((obj:any) => {
      return { 
        columnDef: obj.idcolumna,
        header: obj.cabecera,
        class: obj.class,
        //cell: (element: any) => `${element[obj.idcolumna] ? element[obj.idcolumna] : ``}`  
        cell: (element: any) => {
          if(obj.idcolumna==="saldo"){
            return `${element[obj.idcolumna] ? (element[obj.idcolumna]).toFixed(2) : `0`}`
          }
          else{
            return `${element[obj.idcolumna] ? element[obj.idcolumna] : ``}`
          }
        } 
      }
    });

    this.displayedColumns = data.map((obj:any) => obj.idcolumna);

    this.DocTKBLPT= [];
    this.DocTKBLPTTableDataSource.data=this.DocTKBLPT; 
    this.DocTKBLPTTableDataSource.paginator=this.paginator;

    if(this.ValidarObtenerLista()){
      this.LlenaGrilla(0);
    }
  }

  alertMsg(msg:string){
    Swal.fire({
      html:msg,
      icon:'info'
    })
  }

  ValidarFechas(){
    if (this.FrmConsultarDoc.controls["FecIni"].value === null || 
      this.FrmConsultarDoc.controls["FecIni"].value.length === 0 || 
      /^\s+$/.test(this.FrmConsultarDoc.controls["FecIni"].value)) {
        this.alertMsg('Ingrese Fecha de Inicio o establezca el formato correcto');
        return false;
    }

    if (this.FrmConsultarDoc.controls["FecEnd"].value === null || 
      this.FrmConsultarDoc.controls["FecEnd"].value.length === 0 || 
      /^\s+$/.test(this.FrmConsultarDoc.controls["FecEnd"].value)) {
        this.alertMsg('Ingrese Fecha Fin o establezca el formato correcto');
        return false;
    }

    var valfechaini = new Date(this.FrmConsultarDoc.controls["FecIni"].value);
    var valfechafin = new Date(this.FrmConsultarDoc.controls["FecEnd"].value);

    if (!this.ObjFunctions.ValidateDateRange(valfechaini,valfechafin)) {
      this.alertMsg('Fecha Fin tiene que ser mayor que Fecha de Inicio.');
      return false;
    }

    return true;
  }

  Buscar(){
    if(this.ValidarFechas()){
      this.LlenaGrilla(1);
    }
  }

  convertFechaDate(fecha: Date) {
    const dia: string = fecha.getDate().toString().padStart(2, '0');
    const mes: string = (fecha.getMonth() + 1).toString().padStart(2, '0');
    const anio: string = fecha.getFullYear().toString();
    return dia + '/' + mes + '/' + anio; 
  }

  LlenaGrilla(opc:number){
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => {
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjDocTKBLPTService.Listar(this.FrmConsultarDoc.controls["selectBuscar"].value,
                                              this.FrmConsultarDoc.controls["txtFiltro"].value,
                                              this.convertFechaDate(new Date(this.FrmConsultarDoc.controls["FecIni"].value)),
                                              this.convertFechaDate(new Date(this.FrmConsultarDoc.controls["FecEnd"].value)))
                .subscribe(data => {
                  if (data.isSuccess){
                    this.DocTKBLPT= data.data;
                    this.DocTKBLPTTableDataSource.data=this.DocTKBLPT; 
                    if(opc===1 && this.DocTKBLPT.length===0){
                      Swal.fire({
                        html:'No existen registros con estos filtros',
                        icon:'info'
                      });
                    }
                  }                                         
                }); 

              this.DocTKBLPTTableDataSource.paginator=this.paginator;
            }
            else{
                variablesGlobales.token='';
                variablesGlobales.usuario.codusu=0;
                this.router.navigate(['login']);
            }
          }
        },
        error => {
          console.log(error);
          variablesGlobales.token='';
          variablesGlobales.usuario.codusu=0;
          this.router.navigate(['login']);
        }   
      ); 
  }

  fnSoloNumeros(event:any) {
    var tecla = (document.all) ? event.keyCode : event.which;
    if (tecla===8) return true;
    var patron =/[0-9]/;
    var te = String.fromCharCode(tecla);
    return patron.test(te);
  }

  fnIngNumDocumento(event:any){
    if(this.FrmConsultarDoc.controls["selectBuscar"].value===0 ||
      this.FrmConsultarDoc.controls["selectBuscar"].value===10 ||
      this.FrmConsultarDoc.controls["selectBuscar"].value===11 ||
      this.FrmConsultarDoc.controls["selectBuscar"].value===20 ||
      this.FrmConsultarDoc.controls["selectBuscar"].value===21){
        return this.fnSoloNumeros(event);
    } 
    return true;
  }

  Limpiar(){
    this.FrmConsultarDoc.controls["txtFiltro"].setValue("");
  }

  Imprimir(id:number){
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              if(this.FrmConsultarDoc.controls["selectTipoDoc"].value==="1"){
                this.ObjTicketService.Obtener(id)
                  .subscribe(
                    data => {
                      if (data.isSuccess){
                        this.ObjTicket=data.ticket;

                        this.pdfTK.getPdfData(this.ObjTicket);
                      }
                      else{
                        Swal.fire({
                          title: 'Error al procesar en BD',
                          icon: 'warning'}); 
                      }                                         
                    },
                    error => {
                      console.log(error);
                      Swal.fire({
                        title:'El servicio solicitado no se encuentra disponible',
                        icon: 'error',
                      });
                    }
                  );
              }
              else if(this.FrmConsultarDoc.controls["selectTipoDoc"].value==="2"){
                this.ObjBoletaService.Obtener(id)
                  .subscribe(
                    data => {
                      if (data.isSuccess){
                        this.ObjBoleta=data.boleta;

                        this.pdfBL.getPdfData(this.ObjBoleta);
                      }
                      else{
                        Swal.fire({
                          title: 'Error al procesar en BD',
                          icon: 'warning'}); 
                      }                                         
                    },
                    error => {
                      console.log(error);
                      Swal.fire({
                        title:'El servicio solicitado no se encuentra disponible',
                        icon: 'error',
                      });
                    }
                  );
              }
              else if(this.FrmConsultarDoc.controls["selectTipoDoc"].value==="3"){
                let nombre_reporte;

                if(id>0) nombre_reporte="Prestamo";
                else nombre_reporte="Venta";

                this.ObjDeudaService.Obtener(nombre_reporte, Math.abs(id))
                  .subscribe(
                    data => {
                      if (data.isSuccess){
                        if(id>0) this.ObjDeuda=data.prestamo;
                        else this.ObjDeuda=data.venta;
                        

                        this.pdfPT.getPdfData(this.ObjDeuda, id);
                      }
                      else{
                        Swal.fire({
                          title: 'Error al procesar en BD',
                          icon: 'warning'}); 
                      }                                         
                    },
                    error => {
                      console.log(error);
                      Swal.fire({
                        title:'El servicio solicitado no se encuentra disponible',
                        icon: 'error',
                      });
                    }
                  );
              }
            }
            else{
                variablesGlobales.token='';
                variablesGlobales.usuario.codusu=0;
                this.router.navigate(['login']);
            }
          }
        },
        error => {
          console.log(error);
          variablesGlobales.token='';
          variablesGlobales.usuario.codusu=0;
          this.router.navigate(['login']);
        }   
      );
  }
}
