<div fxLayout="column" fxLayoutAlign="center" class="containers">
    <form [formGroup]="FrmConsultarDoc">
        <mat-card fxLayout="column">
            <mat-card-title class="Titulo">Consultar Documento</mat-card-title><br>
            <mat-card-content fxLayout="column">
                <fieldset class="borderFieldset">
                    <mat-card-content fxLayout="column">
                        <mat-form-field class="Fila">
                            <mat-select placeholder="Tipo Documento" formControlName="selectTipoDoc" (selectionChange)="fnListarFiltro($event.value)">
                                <mat-option value="1">Ticket's</mat-option>
                                <mat-option value="2">Boletas de Pago</mat-option>  
                                <mat-option value="3">Deudas</mat-option>                                
                            </mat-select>
                        </mat-form-field>
                    </mat-card-content>
                    <mat-card-content fxLayout="row">
                        <mat-form-field class="Fecha" >
                            <input matInput [matDatepicker]="FecIni" placeholder="Desde" formControlName="FecIni">
                            <mat-datepicker-toggle matSuffix [for]="FecIni"></mat-datepicker-toggle>
                            <mat-datepicker #FecIni></mat-datepicker>
                        </mat-form-field> 
                        <mat-form-field class="Fecha">
                                <input matInput [matDatepicker]="FecEnd" placeholder="Hasta" formControlName="FecEnd">
                                <mat-datepicker-toggle matSuffix [for]="FecEnd" ></mat-datepicker-toggle>
                                <mat-datepicker #FecEnd></mat-datepicker>
                        </mat-form-field>
                    </mat-card-content>
                    <mat-card-content fxLayout="row">
                        <mat-form-field class="Fila">
                            <mat-select placeholder="Filtrar por" formControlName="selectBuscar" (selectionChange)="Limpiar()">       
                                <mat-option *ngFor="let elem of Filtro" [value]="elem.codigo">
                                    {{elem.descripcion}}
                                </mat-option>                       
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="Fila1">
                            <input matInput aria-label="string" placeholder="Buscar" formControlName="txtFiltro" (keypress)="fnIngNumDocumento($event)">
                        </mat-form-field>
                        <button mat-icon-button class="tooltips_fab" (click)="Buscar()">
                            <mat-icon>search</mat-icon>
                            <span>Buscar</span>
                        </button>
                    </mat-card-content>   
                </fieldset>            
            </mat-card-content>
            <mat-card-content fxLayout="column">
                <h3 class="Titulo">Lista de Documentos Emitidos</h3>
                <table mat-table #table [dataSource]="DocTKBLPTTableDataSource">
                    <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
                        <th mat-header-cell *matHeaderCellDef [class]="column.class">{{column.header}}</th>
                        <td mat-cell *matCellDef="let row" [class]="column.class">
                            <button mat-icon-button class="tooltips_cls" *ngIf="column.columnDef==='codigo'" (click)="Imprimir(column.cell(row))">
                                <mat-icon fontSet="material-icons-outlined">print</mat-icon>
                                <span>Imprimir</span>
                            </button>  
                            <div *ngIf="column.columnDef!=='codigo'">{{column.cell(row)}}</div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </mat-card-content>
        </mat-card> 
    </form>      
</div>
<mat-paginator class="stylePaginator" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>


