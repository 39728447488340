export let variablesGlobales={
    autentica:{
        tX_LOGIN:'RAKLER_SIG',
        tX_CLAVE: '456123'
    },
    token:'',
    usuario:{
        codusu:0,
        numdoc:'',
        nombre:'',
        codrol:0,
        rol:''   
    },
    time_Out: 1800 // tiempo en segundos para el cierre de sesión.
}