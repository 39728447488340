import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuracion } from './config';
import { variablesGlobales } from 'src/app/Util/variableGlobal';

@Injectable({
    providedIn: 'root'
})

export class AutenticaService {
    public url:string = Configuracion.url2 + 'Autentica/';
    
    constructor(private _http: HttpClient) { }

    ValidarApp(){
        return this._http.post<any>(this.url + 'Usuario', variablesGlobales.autentica);
    }
}