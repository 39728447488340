import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MenuComponent } from './menu.component';
import { InicioComponent } from './inicio/inicio.component';
import { GestionarProveedorComponent } from './gestionar-proveedor/gestionar-proveedor.component';
import { ConsultarDocumentoComponent } from './consultar-documento/consultar-documento.component';
import { ActualizaClaveComponent } from './actualiza-clave/actualiza-clave.component';
import { RecordEntregaComponent } from './record-entrega/record-entrega.component';

const routes: Routes = [{
  path: '',
  component: MenuComponent,
  children: [   
    {
      path: 'inicio',
      component: InicioComponent,
    },
    {
      path: '',
      redirectTo: 'inicio',
      pathMatch: 'full',
    },
    {
      path: 'GestionarProveedor',
      component: GestionarProveedorComponent
    },
    {
      path: 'ConsultarDocumento',
      component: ConsultarDocumentoComponent
    },
    {
      path: 'RecordEntrega',
      component: RecordEntregaComponent
    },
    {
      path: 'ActualizaClave',
      component: ActualizaClaveComponent
    }
  ],
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MenuRoutingModule { }
