import { Injectable } from '@angular/core';
import { ImageToBase64 } from '../helpers/ImageToBase64';
import { Functions } from '../helpers/Functions';

import * as pdfMake from "pdfmake/build/pdfmake.min.js";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { ResumenTicketProveedor } from '../models/ResumenTicketProveedor';
import { Ticket } from '../models/Ticket';
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

@Injectable({
    providedIn: 'root'
})

export class RptPDFRecordTicket {

    total:any;

    constructor(private canvas: ImageToBase64,
                private ObjFunctions: Functions) { }

    convertFecha(fecha: string): string{
        var parts=fecha.split("-");
        var anio =parts[0];
        var mes =parts[1];
        var dia =parts[2].substring(0,2);
    
        return dia+"/"+mes+"/"+anio;
    }

    /** Diseño de cabecera **/
    setTitle(logo: any, FecIni: string, FecEnd: string, num_pagina: number): any {
        //Margen de la cabecera [left,top,right,bottom]
        let margin = [25, 25, 25, 15];

        let fontSizeTitle=16;
        let fontSizePrint=8;

        //Dimensiones del logo
        let widthLogo=100;
        let heightLogo=38.38;

        return [
            {   margin:margin,
                table: {
                    widths:['*'],
                    headerRows: 1,
                    body:[
                        [
                            {
                                table:{
                                    widths:['19%','*','25.5%'],
                                    body:[
                                        [
                                            //logo de la empresa
                                            {
                                                image: logo,
                                                width: widthLogo,
                                                height: heightLogo,
                                                rowSpan:3
                                            },
                                            //Título del documento
                                            {
                                                text: "RECORD DE TICKET DE PALMERO",
                                                fontSize: fontSizeTitle,
                                                bold: true,
                                                alignment : 'center',
                                                rowSpan:2,
                                                //margin:[0,11,0,0]
                                            },
                                            //Datos de impresión
                                            {                                                
                                                text: "PÁG: "+num_pagina, fontSize: 9, alignment : 'right'
                                            }
                                        ],
                                        [
                                            {}, 
                                            {}, 
                                            {text: this.ObjFunctions.GetDate().substring(0,10), fontSize: fontSizePrint, alignment : 'right'},
                                        ],
                                        [
                                            {}, 
                                            {text: "DEL "+this.ObjFunctions.GetFecha(FecIni)+" AL "+this.ObjFunctions.GetFecha(FecEnd), fontSize: 9, alignment : 'center'}, 
                                            {text: this.ObjFunctions.GetDate().substring(11,this.ObjFunctions.GetDate().length), fontSize: fontSizePrint, alignment : 'right'}
                                        ]
                                    ]
                                },
                                layout:'noBorders'
                            },
                        ],
                        [{}]
                    ]
                },
                layout: 'headerLineOnly'
            }
        ];
    }

    ShowTableDetalle(data: Ticket[], planta: number, band: number=0): any{
        let tbdetalle={};

        if(data.length>0){
            let dataPlanta: Ticket[]=[];

            let subtotal={
                peso:0,
                importe:0
            };

            for(let item of data){
                if(item.planta==planta){
                    dataPlanta.push(item);

                    subtotal.peso += item.pesoEntrada! - item.pesoSalida! - item.castigo!;
                    subtotal.importe += parseFloat((item.precio!*item.tpocmb!*parseFloat(((item.pesoEntrada! - item.pesoSalida! - item.castigo!)*0.001).toFixed(3))).toFixed(2))-
                        parseFloat((item.flete!*item.tpocmbflete!*parseFloat(((item.pesoEntrada! - item.pesoSalida! - item.castigo!)*0.001).toFixed(3))).toFixed(2));
                }
            }

            if(dataPlanta.length>0){
                tbdetalle={
                    table: {
                        widths: ['9%', '13%', '*', '10.3%', '7.9%', '5.1%', '10.3%'],
                        headerRows: 1,
                        body: this.createTableDetalleBody(
                            this.createTableDetalleHeader(dataPlanta[0].objSede?.sede!),
                            this.mapDataTicket(dataPlanta), 
                            this.createTableDetalleSubTotal(subtotal))
                    }
                };
            }
        }
        else{
            if(band==1){
                tbdetalle={
                    table: {
                        widths: ['*'],
                        body: [
                            [
                                {text: ' ', border: [false, false, false, false] },
                            ]
                        ]
                    }
                };
            }
        }
        
        return tbdetalle;
    }

    /** Método para crear el encabezado de la tabla Detalle**/
    createTableDetalleHeader(cabecera: string): any {
        let pageHeader:any = { 
            fila_0: {
                col_1:{ text: cabecera, colSpan: 7, style: 'tableHeader', border: [false,false,false,false], margin: [-5,0,0,0]},
                col_2:{},
                col_3:{},
                col_4:{},
                col_5:{},
                col_6:{},
                col_7:{}
            }
        };

        return pageHeader;
    }

    /** Método para crear el subtotal de la tabla Detalle**/
    createTableDetalleSubTotal(data: any): any {
        let pageSubTotal:any = { 
            fila_0: {
                col_1:{ text: '', colSpan: 3, border: [false,false,false,false] },
                col_2:{},
                col_3:{},
                col_4:{ text: this.ObjFunctions.formatNumberES(data.peso,2), style: 'tableResult', border: [false,true,false,false], alignment: 'right', margin: [0,0,0,10] },
                col_5:{ text: '', border: [false,true,false,false] },
                col_6:{ text: '', border: [false,true,false,false]},
                col_7:{ text: this.ObjFunctions.formatNumberES(data.importe,2), style: 'tableResult', border: [false,true,false,false], margin:[0,0,-5,10], alignment: 'right' }
            }
        };

        return pageSubTotal;
    }

    /** Método para crear el contenido de la tabla Detalle **/
    createTableDetalleBody(headers: any, data: any[], subtotal: any): any {
        const body = [];

        for (const key in headers) {
            const row = [];

            for (const headerKey in headers[key]) {
                row.push(headers[key][headerKey]);
            }

            body.push(row);
        }

        data.forEach((record:any) => {
            const row = [];

            for (const key in record) {
                switch(key){
                    case "fecha":
                        row.push({ text: record[key], style: 'tableContent', border: [false,false,false,false], margin:[-5,0,0,0] });
                        break;
                    case "numero":
                    case "lugar":
                        row.push({ text: record[key], style: 'tableContent', border: [false,false,false,false] });
                        break;
                    case "importe":
                        row.push({ text: record[key], style: 'tableContent', border: [false,false,false,false], margin:[0,0,-5,0], alignment: 'right' });
                        break;
                    default:
                        row.push({ text: record[key], style: 'tableContent', border: [false,false,false,false], alignment: 'right' });
                        break;
                }
            }

            body.push(row);
        });

        for (const key in subtotal) {
            const row = [];

            for (const subtotalKey in subtotal[key]) {
                row.push(subtotal[key][subtotalKey]);
            }

            body.push(row);
        }

        return body;
    }

    CalculaTotal(data: Ticket[]){
        this.total={
            peso:0,
            importe:0
        };

        for(let item of data){
            this.total.peso += item.pesoEntrada! - item.pesoSalida! - item.castigo!;
            this.total.importe += parseFloat((item.precio!*item.tpocmb!*parseFloat(((item.pesoEntrada! - item.pesoSalida! - item.castigo!)*0.001).toFixed(3))).toFixed(2))-
                parseFloat((item.flete!*item.tpocmbflete!*parseFloat(((item.pesoEntrada! - item.pesoSalida! - item.castigo!)*0.001).toFixed(3))).toFixed(2));
        }
    }

    /** Definición del documento PDF **/
    getDocDefinition(logo: any, obj: ResumenTicketProveedor, FecIni: string, FecEnd: string, orientation: string, pageSize: string): any {
        this.CalculaTotal(obj.ticket!);

        let pageMargin=[25, 80, 25, 55];
        let fontSizeBody=9;
        let fontSizeResult=11;
        let headerFontTable=9;
        let bodyFontTable=9;
    
        const docDefinition = {
            pageOrientation: orientation,
            pageSize,
            pageMargins: pageMargin,
            header: (currentPage: number, pageCount: number) => {
                return this.setTitle(logo, FecIni, FecEnd, currentPage);
            },
            content: [
                {   
                    table: {
                        widths: ['13%','*'],
                        body: [
                            [
                                { text: 'Palmicultor:', bold: true, fontSize: fontSizeBody},
                                { text: obj.prv?.dni_ruc+"\t\t\t"+obj.prv?.razonSocial, fontSize: fontSizeBody}
                            ],
                            [
                                { text: 'Dirección:', bold: true, fontSize: fontSizeBody},
                                { text: obj.prv?.direccion, fontSize: fontSizeBody},
                            ],
                        ]
                    },
                    layout: 'noBorders'
                },
                {
                    margin: [0, 15, 0, 0],
                    table: {
                        widths: ['9%', '13%', '*', '10.3%', '7.9%', '5.1%', '10.3%'],
                        body: [
                            [
                                {text: 'Fecha', style: 'tableInit', bold: true, margin:[-5,0,0,0], border: [false,false,false,true]},
                                {text: 'Ticket', style: 'tableInit', bold: true, border: [false,false,false,true], alignment: 'center'},
                                {text: 'Lugar', style: 'tableInit', bold: true, border: [false,false,false,true], alignment: 'center'},
                                {text: 'Peso', style: 'tableInit', bold: true, border: [false,false,false,true]},
                                {text: 'Precio', style: 'tableInit', bold: true, border: [false,false,false,true]},
                                {text: 'TC', style: 'tableInit', bold: true, border: [false,false,false,true]},
                                {text: 'Importe', style: 'tableInit', bold: true, margin:[0,0,-5,0], border: [false,false,false,true]}
                            ]
                        ]
                    }
                },
                this.ShowTableDetalle(obj.ticket!,1,1),
                this.ShowTableDetalle(obj.ticket!,2),
                {
                    table: {
                        widths: ['*', '10.3%', '7%', '5.1%', '10.3%'],
                        body: [
                            [
                                { text: '', border: [false,false,false,false], margin: [0,0,0,10]},    
                                { text: this.ObjFunctions.formatNumberES(this.total.peso,2), style: 'tableResult', border: [false,true,false,true], margin: [0,0,0,10], alignment: 'right'}, 
                                { text: '', border: [false,true,false,true], margin: [0,0,0,10]},
                                { text: '', border: [false,true,false,true], margin: [0,0,0,10]},   
                                { text: this.ObjFunctions.formatNumberES(this.total.importe,2), style: 'tableResult', border: [false,true,false,true], margin: [0,0,-5,10], alignment: 'right'}, 
                            ],
                            [
                                { text: '', border: [false,false,false,false]},
                                { text: '', border: [false,false,false,true], colSpan:4},  
                                {},       
                                {},
                                {}                 
                            ]
                        ]
                    },
                }
            ],
            styles: {
                tableHeader: {
                    fontSize: headerFontTable,
                    bold: true
                },
                tableInit: {
                    fontSize: 8
                },
                tableContent: {
                    fontSize: bodyFontTable
                },
                tableResult: {
                    fontSize: fontSizeResult,
                    bold: true
                }
            }
        };

        return docDefinition;
    }

    generatePdf(docDefinition:any): void {
        const pdfObject = pdfMake.createPdf(docDefinition);
        pdfObject.open();
    }

    getPdfData(obj: ResumenTicketProveedor, FecIni: string, FecEnd: string): void {
        //Se obtiene la trama de la imagen en assets que se va a incorporar en el documento
        this.canvas.getBase64Image('./assets/img/Olamsa.png')
        .then(base64Img => {
            const logo = base64Img;
            this.generatePdf(this.getDocDefinition(logo, obj, FecIni, FecEnd, 'portrait', 'A4'));
        });

    }

    mapDataTicket(data: Ticket[]): any {
        return data.map((item) => {
            return {
                fecha:this.convertFecha(item.fechasalida!),
                numero:item.numero,
                lugar:item.sect?.descripcion+" - "+item.objComite?.descripcion,
                peso:this.ObjFunctions.formatNumberES(item.pesoEntrada! - item.pesoSalida! - item.castigo!),
                precio:item.precio,
                tipocambio:item.tpocmb!.toFixed(2),
                importe: this.ObjFunctions.formatNumberES(parseFloat((item.precio!*item.tpocmb!*parseFloat(((item.pesoEntrada! - item.pesoSalida! - item.castigo!)*0.001).toFixed(3))).toFixed(2))-
                    parseFloat((item.flete!*item.tpocmbflete!*parseFloat(((item.pesoEntrada! - item.pesoSalida! - item.castigo!)*0.001).toFixed(3))).toFixed(2)),2)
            };
        });
    }
}