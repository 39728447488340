import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { variablesGlobales } from './Util/variableGlobal';

@Component({
  selector: 'app-root',
  /*templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']*/
  template: '<router-outlet></router-outlet>'
})
export class AppComponent {
  title = 'Front';
  userActivity: any;
  userInactive: Subject<any> = new Subject();
  
  ngOnInit() {}

  constructor(private router:Router){
    this.userInactive.subscribe(()=>{
      variablesGlobales.token='';
      variablesGlobales.usuario.codusu=0;
      this.router.navigate(['login']);
    });
  }

  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }

  setTimeout() {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), variablesGlobales.time_Out*1000);
  }
}
