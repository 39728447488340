<mat-sidenav-container class="sidenav-container">
    <mat-sidenav #drawer class="sidenav" fixedInViewport="true"
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="!(isHandset$ | async)"
        >
      <mat-toolbar class="menu"><div class="txtMenu">Opciones</div> </mat-toolbar>
      <mat-nav-list style="margin-top: 10px;">
        <mat-list-item class="itemMenu" *ngFor="let nav of Menu1">        
          <a class="btnStyle" [routerLink]="nav.route">{{nav.name}}</a>
        </mat-list-item>
        <mat-list-item class="itemMenu" (click)="CerrarSesion()">
          <button mat-button mat-icon-button [disableRipple]="true" class="btnStyle">{{Menu2.name}}</button>
        </mat-list-item>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content fxLayout="column">
      <div class="cabecera">
          <mat-toolbar class="mat-toolbar_principal">         
            <button
              type="button"
              aria-label="Toggle sidenav"
              mat-icon-button
              (click)="drawer.toggle()">          
              <mat-icon aria-label="Side nav toggle icon" style="color: #424242;">menu</mat-icon>
            </button>
            <span style="text-align: start;"> 
                <div>
                  <p class="titulo">Consulta de Tickets de Pesaje</p>
                  <p class="subtitulo">{{usuarioname}}</p>
                </div>
            </span>
            <span style="flex: 1 1 auto;"></span>
            <img src="assets/img/Olamsa.png" style="width:160px !important;">  
          </mat-toolbar>   
          <mat-toolbar style="height: 5px; background: linear-gradient(to left, #ff3300 31%, #ffcc00 100%);"></mat-toolbar> 
      </div>
      <div class="pagina">
        <router-outlet></router-outlet>       
      </div>     
      <ng-content>        
      </ng-content>
      <div style="width: 100%;"> 
        <mat-toolbar class="fondo_pie" fxLayout="row" fxLayoutAlign="space-between start">
          <div class="contenido">  
            <div class="row">
              <div class="col">
                <a href="https://www.olamsa.com.pe/" target="_blank" style="text-decoration: none;">
                  <strong class="formato">Olamsa</strong>
                </a>
              </div>
              <div class="col">
                <p class="formatoVersion">versión 1.0</p>
              </div>
            </div>          
          </div>
        </mat-toolbar>
     </div>
    </mat-sidenav-content>    
  </mat-sidenav-container>


