import { Injectable } from '@angular/core';
import { ImageToBase64 } from '../helpers/ImageToBase64';
import { Functions } from '../helpers/Functions';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import { Ticket } from '../models/Ticket';
import { Boleta } from '../models/Boleta';
import { Descuento } from '../models/Descuento';

import * as pdfMake from "pdfmake/build/pdfmake.min.js";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

@Injectable({
    providedIn: 'root'
})

export class RptPDFBoleta {

    totalIngresos:any;
    totalDescuentos:any;

    constructor(private canvas: ImageToBase64,
                private ObjFunctions: Functions) { }

    convertFecha(fecha: string): string{
        var parts=fecha.split("-");
        var anio =parts[0];
        var mes =parts[1];
        var dia =parts[2].substring(0,2);
    
        return dia+"/"+mes+"/"+anio;
    }

    /** Diseño de cabecera **/
    setTitle(logo: any, nro_boleta: string): any {
        //Margen de la cabecera [left,top,right,bottom]
        let margin = [25, 25, 25, 15];

        let fontSizeTitle=16;
        let fontSizePrint=8;

        //Dimensiones del logo
        let widthLogo=100;
        let heightLogo=38.38;

        return [
            {   margin:margin,
                table: {
                    widths:['*'],
                    headerRows: 1,
                    body:[
                        [
                            {
                                table:{
                                    widths:['19%','*','25.5%'],
                                    body:[
                                        [
                                            //logo de la empresa
                                            {
                                                image: logo,
                                                width: widthLogo,
                                                height: heightLogo,
                                                rowSpan:3
                                            },
                                            //Título del documento
                                            {
                                                text: 'BOLETA DE PAGO DE ACOPIO',
                                                fontSize: fontSizeTitle,
                                                bold: true,
                                                alignment : 'center',
                                                rowSpan:3,
                                                margin:[0,12,0,0]
                                            },
                                            //Datos de impresión
                                            {
                                                text: [
                                                    { text: 'Impreso: ', bold: true},
                                                    { text: variablesGlobales.usuario.numdoc+' - '+this.ObjFunctions.GetDate().substring(0,10)}
                                                ],
                                                fontSize: fontSizePrint,
                                                alignment : 'right'
                                            }
                                        ],
                                        [
                                            {}, {}, {text: this.ObjFunctions.GetDate().substring(11,this.ObjFunctions.GetDate().length), fontSize: fontSizePrint, alignment : 'right'}
                                        ],
                                        [
                                            {}, {}, {text: "Nro: "+nro_boleta, fontSize: 11, bold: true, alignment : 'right'},
                                        ]
                                    ]
                                },
                                layout:'noBorders'
                            },
                        ],
                        [{}]
                    ]
                },
                layout: 'headerLineOnly'
            }
        ];
    }

    /** Método para crear el encabezado de la tabla Ingresos**/
    createTableIngresosHeader(): any {
        let pageHeader:any = { 
            fila_0: {
                col_1:{ text: "INGRESOS", colSpan: 11, style: 'tableHeader', border: [false,false,false,false], margin: [-5,0,0,0]},
                col_2:{},
                col_3:{},
                col_4:{},
                col_5:{},
                col_6:{},
                col_7:{},
                col_8:{},
                col_9:{},
                col_10:{},
                col_11:{}
            }
        };

        return pageHeader;
    }

    /** Método para crear el contenido de la tabla Ingresos **/
    createTableIngresosBody(headers: any, data: any[]): any {
        const body = [];

        for (const key in headers) {
            const row = [];

            for (const headerKey in headers[key]) {
                row.push(headers[key][headerKey]);
            }

            body.push(row);
        }

        data.forEach((record:any) => {
            const row = [];

            for (const key in record) {
                switch(key){
                    case "fecha":
                        row.push({ text: record[key], style: 'tableInit', border: [false,false,false,false], margin:[-5,0,0,0] });
                        break;
                    case "numero":
                        row.push({ text: record[key], style: 'tableInit', border: [false,false,false,false] });
                        break;
                    case "localidad":
                        row.push({ text: record[key], style: 'tableContent', border: [false,false,false,false] });
                        break;
                    case "importe":
                        row.push({ text: record[key], style: 'tableContent', border: [false,false,false,false], margin:[0,0,-5,0], alignment: 'right' });
                        break;
                    default:
                        row.push({ text: record[key], style: 'tableContent', border: [false,false,false,false], alignment: 'right' });
                        break;
                }
            }

            body.push(row);
        });

        return body;
    }

    SubTotalIngresos(data: Ticket[]){
        this.totalIngresos={
            peso_neto:0,
            valor_compra:0,
            flete_soles:0,
            importe:0,
            importe_sinredondear:0
        };

        for(let item of data){
            this.totalIngresos.peso_neto += parseFloat(((item.pesoEntrada! - item.pesoSalida! - item.castigo!)*0.001).toFixed(3));
            /*this.totalIngresos.valor_compra += parseFloat((item.precio!*item.tpocmb!*this.totalIngresos.peso_neto).toFixed(2));
            this.totalIngresos.flete_soles += parseFloat((item.flete!*item.tpocmbflete!*this.totalIngresos.peso_neto).toFixed(2));
            this.totalIngresos.importe += parseFloat((this.totalIngresos.valor_compra-this.totalIngresos.flete_soles).toFixed(2));
            this.totalIngresos.importe_sinredondear += this.totalIngresos.valor_compra-this.totalIngresos.flete_soles;*/
            this.totalIngresos.valor_compra += parseFloat((item.precio!*item.tpocmb!*parseFloat(((item.pesoEntrada! - item.pesoSalida! - item.castigo!)*0.001).toFixed(3))).toFixed(2));
            this.totalIngresos.flete_soles += parseFloat((item.flete!*item.tpocmbflete!*parseFloat(((item.pesoEntrada! - item.pesoSalida! - item.castigo!)*0.001).toFixed(3))).toFixed(2));
            this.totalIngresos.importe += parseFloat((parseFloat((item.precio!*item.tpocmb!*parseFloat(((item.pesoEntrada! - item.pesoSalida! - item.castigo!)*0.001).toFixed(3))).toFixed(2))-
            parseFloat((item.flete!*item.tpocmbflete!*parseFloat(((item.pesoEntrada! - item.pesoSalida! - item.castigo!)*0.001).toFixed(3))).toFixed(2))).toFixed(2));
            this.totalIngresos.importe_sinredondear += parseFloat((item.precio!*item.tpocmb!*parseFloat(((item.pesoEntrada! - item.pesoSalida! - item.castigo!)*0.001).toFixed(3))).toFixed(2))-
            parseFloat((item.flete!*item.tpocmbflete!*parseFloat(((item.pesoEntrada! - item.pesoSalida! - item.castigo!)*0.001).toFixed(3))).toFixed(2));
        }
    }

    /** Método para crear el encabezado de la tabla Descuentos**/
    createTableDescuentosHeader(): any {
        let pageHeader:any = { 
            fila_0: {
                col_1:{ text: "DESCUENTOS", colSpan: 4, style: 'tableHeader', border: [false,false,false,false], margin: [-5,0,0,0]},
                col_2:{},
                col_3:{},
                col_4:{}
            }
        };

        return pageHeader;
    }

    /** Método para crear el contenido de la tabla Descuentos **/
    createTableDescuentosBody(headers: any, data: any[]): any {
        const body = [];

        for (const key in headers) {
            const row = [];

            for (const headerKey in headers[key]) {
                row.push(headers[key][headerKey]);
            }

            body.push(row);
        }

        data.forEach((record:any) => {
            const row = [];

            for (const key in record) {
                switch(key){
                    case "fecha":
                        row.push({ text: record[key], style: 'tableInit', border: [false,false,false,false], margin:[-5,0,0,0] });
                        break;
                    case "tipoDescuento":
                        row.push({ text: record[key], style: 'tableInit', border: [false,false,false,false] });
                        break;
                    case "descripcion":
                        row.push({ text: record[key], style: 'tableContent', border: [false,false,false,false] });
                        break;
                    case "importe":
                        row.push({ text: record[key], style: 'tableContent', border: [false,false,false,false], margin:[0,0,-5,0], alignment: 'right' });
                        break;
                }
            }

            body.push(row);
        });

        return body;
    }

    ShowTableDescuentos(data: Descuento[]): any{
        let tbdescuento={};

        if(data!=null){
            if(data.length>0){
                tbdescuento={
                    table: {
                        widths: ['7.5%', '8%', '*', '7%'],
                        headerRows: 1,
                        body: this.createTableDescuentosBody(this.createTableDescuentosHeader(), this.mapDataDescuento(data))
                    }
                };
            }
        }
        
        return tbdescuento;
    }

    SubTotalDescuentos(data: Descuento[]){
        this.totalDescuentos={
            importe:0
        };

        if(data!=null){
            for(let item of data){
                this.totalDescuentos.importe += item.importe;
            }
        }
    }

    ShowSubTotalDescuentos(cantidad:number): any{
        let tbtotal={};

        if(cantidad>0){
            tbtotal={
                table: {
                    widths: ['*', '7.5%', '7.6%', '6.1%', '7.1%'],
                    body: [
                        [
                            { text: 'Total', style: 'tableInit', bold: true, border: [false,false,false,false], alignment: 'right'},  
                            { text: '', border: [false,true,false,false]}, 
                            { text: '', border: [false,true,false,false]}, 
                            { text: '', border: [false,true,false,false]}, 
                            { text: this.ObjFunctions.formatNumberES(this.totalDescuentos.importe,2), style: 'tableInit', bold: true, margin:[0,0,-5,0], border: [false,true,false,false], alignment: 'right'}                        
                        ]
                    ]
                }
            };
        }

        return tbtotal;
    }

    /** Definición del documento PDF **/
    getDocDefinition(logo: any, obj: Boleta, orientation: string, pageSize: string): any {
        this.SubTotalIngresos(obj.detalleTicket!);
        this.SubTotalDescuentos(obj.detalleDescuento!);

        let pageMargin=[25, 80, 25, 55];
        let fontSizeBody=9;
        let fontSizeResult=11;
        let headerFontTable=9;
        let bodyFontTable=9;
    
        const docDefinition = {
            pageOrientation: orientation,
            pageSize,
            pageMargins: pageMargin,
            header: this.setTitle(logo, obj.numero!),
            footer: (currentPage: number, pageCount: number) => {
                return { text: 'Página ' + currentPage.toString() + ' de ' + pageCount, alignment: 'center', margin: [0, 30, 0, 0], fontSize: 8 };
            },
            content: [
                {
                    table: {
                        widths: ['12%', '50%', '7%', '*'],
                        body: [
                            [
                                {text:'Periodo:', fontSize: fontSizeBody},
                                {text:obj.planilla, fontSize: fontSizeBody, colSpan:3},
                                {},
                                {}
                            ],
                            [
                                {text:'DNI:', fontSize: fontSizeBody},
                                {text:obj.dni_ruc, fontSize: fontSizeBody},
                                {text:'Banco:', fontSize: fontSizeBody},
                                {text:obj.objbco?.descripcion, fontSize: fontSizeBody}
                            ],
                            [
                                {text:'Palmicultor:', fontSize: fontSizeBody},
                                {text:obj.razonsocial, fontSize: fontSizeBody},
                                {text:'Cta Cte:', fontSize: fontSizeBody},
                                {text:obj.ctacte, fontSize: fontSizeBody}
                            ],
                            [
                                {text:'Comprobante:', fontSize: fontSizeBody},
                                {text:this.convertFecha(obj.fecreg!)+'\t\t '+obj.objTS?.nro_TS, fontSize: fontSizeBody},
                                {text:'Planta:', fontSize: fontSizeBody},
                                {text:obj.objSede?.abrev, fontSize: fontSizeBody}
                            ],
                            [
                                {text:'Medio Pago:', fontSize: fontSizeBody},
                                {
                                    text:obj.objTS?.objMedioPago?.descripcion+' Nro: '+obj.objTS?.nro_MedioPago+
                                        ((obj.objTS?.ctacte!=null && obj.objTS?.ctacte.trim().length>0)?' Cuenta: '+obj.objTS?.ctacte:''),
                                    fontSize: fontSizeBody, 
                                    colSpan:3
                                },
                                {},
                                {}
                            ],
                        ]
                    },
                    layout: 'noBorders'
                },
                {
                    margin: [0, 5, 0, 0],
                    table: {
                        widths: ['8.5%', '9.1%', '*', '3.7%', '5.5%', '7.9%', '5.1%', '8.4%', '8.5%', '6.8%', '8%'],
                        body: [
                            [
                                {text: 'Fecha', style: 'tableInit', bold: true, margin:[-5,10,0,0], border: [false,false,false,true]},
                                {text: 'Ticket', style: 'tableInit', bold: true, margin:[0,10,0,0], border: [false,false,false,true], alignment: 'center'},
                                {text: 'Localidad', style: 'tableInit', bold: true, margin:[0,10,0,0], border: [false,false,false,true], alignment: 'center'},
                                {text: 'Cast', style: 'tableInit', bold: true, margin:[0,10,0,0], border: [false,false,false,true], alignment: 'right'},
                                {text: 'Flete $', style: 'tableInit', bold: true, margin:[0,10,0,0], border: [false,false,false,true], alignment: 'right'},
                                {text: 'Precio', style: 'tableInit', bold: true,  margin:[0,10,0,0], border: [false,false,false,true], alignment: 'right'},
                                {text: 'TC', style: 'tableInit', bold: true, margin:[0,10,0,0], border: [false,false,false,true], alignment: 'right'},
                                {text: 'Peso Neto Pagar', style: 'tableInit', bold: true, border: [false,false,false,true], alignment: 'center'},
                                {text: 'Valor de Compra', style: 'tableInit', bold: true, border: [false,false,false,true], alignment: 'center'},
                                {text: 'Flete S/.', style: 'tableInit', bold: true, margin:[0,10,0,0], border: [false,false,false,true], alignment: 'right'},
                                {text: 'Importe S/.', style: 'tableInit', bold: true, margin:[0,10,-5,0], border: [false,false,false,true], alignment: 'right'}
                            ]
                        ]
                    }
                },
                {
                    table: {
                        widths: ['8.5%', '9.1%', '*', '3.7%', '5.5%', '7.9%', '5.1%', '8.4%', '8.5%', '6.8%', '8%'],
                        headerRows: 1,
                        body: this.createTableIngresosBody(this.createTableIngresosHeader(), this.mapDataTicket(obj.detalleTicket!))
                    }
                },
                {
                    table: {
                        widths: ['*', '7.5%', '7.6%', '6.1%', '7.1%'],
                        body: [
                            [
                                { text: 'Total', style: 'tableInit', bold: true, border: [false,false,false,false], alignment: 'right'},  
                                { text: this.ObjFunctions.formatNumberES(this.totalIngresos.peso_neto,3), style: 'tableInit', bold: true, border: [false,true,false,false], alignment: 'right'}, 
                                { text: this.ObjFunctions.formatNumberES(this.totalIngresos.valor_compra,2), style: 'tableInit', bold: true, border: [false,true,false,false], alignment: 'right'}, 
                                { text: this.ObjFunctions.formatNumberES(this.totalIngresos.flete_soles,2), style: 'tableInit', bold: true, border: [false,true,false,false], alignment: 'right'}, 
                                { text: this.ObjFunctions.formatNumberES(this.totalIngresos.importe,2), style: 'tableInit', bold: true, margin:[0,0,-5,0], border: [false,true,false,false], alignment: 'right'}                         
                            ]
                        ]
                    }
                },
                this.ShowTableDescuentos(obj.detalleDescuento!),
                this.ShowSubTotalDescuentos((obj.detalleDescuento==null)?0:obj.detalleDescuento?.length!),
                {
                    table: {
                        widths: ['*', '7.1%'],
                        body: [
                            [
                                { text: 'Redondeo Contable : S/.', style: 'tableInit', bold: true, alignment: 'right'},  
                                { text: this.ObjFunctions.formatNumberES(this.totalIngresos.importe - parseFloat(this.totalIngresos.importe_sinredondear.toFixed(2)),2), style: 'tableInit', alignment: 'right'},                        
                            ]
                        ]
                    },
                    layout:"noBorders"
                },
                {
                    table: {
                        widths: ['*', '17%', '10%'],
                        body: [
                            [
                                { text: '', border: [false,false,false,false], margin: [0,0,0,10],},
                                { text: 'Neto a Cobrar S/.', fontSize: fontSizeResult, bold: true, border: [false,true,false,true], margin: [0,0,0,10], alignment: 'right'},  
                                { 
                                    text: this.ObjFunctions.formatNumberES(this.totalIngresos.importe - parseFloat(this.totalDescuentos.importe.toFixed(2))+
                                        parseFloat((this.totalIngresos.importe - parseFloat(this.totalIngresos.importe_sinredondear.toFixed(2))).toFixed(2)),2), 
                                    fontSize: fontSizeResult, 
                                    bold: true, 
                                    border: [false,true,false,true], 
                                    margin: [0,0,-5,10],
                                     alignment: 'right'},                        
                            ],
                            [
                                { text: '', border: [false,false,false,false]},
                                { text: '', border: [false,false,false,true], colSpan:2},  
                                {},                        
                            ]
                        ]
                    },
                }
            ],
            styles: {
                tableHeader: {
                    fontSize: headerFontTable,
                    bold: true
                },
                tableInit: {
                    fontSize: 8
                },
                tableContent: {
                    fontSize: bodyFontTable
                }
            }
        };

        return docDefinition;
    }

    generatePdf(docDefinition:any): void {
        const pdfObject = pdfMake.createPdf(docDefinition);
        pdfObject.open();
    }

    getPdfData(obj: Boleta): void {
        //Se obtiene la trama de la imagen en assets que se va a incorporar en el documento
        this.canvas.getBase64Image('./assets/img/Olamsa.png')
        .then(base64Img => {
            const logo = base64Img;
            this.generatePdf(this.getDocDefinition(logo, obj, 'portrait', 'A4'));
        });

    }

    mapDataTicket(data: Ticket[]): any {
        return data.map((item) => {
            return {
                fecha:this.convertFecha(item.fechasalida!),
                numero:item.numero?.substring(4,item.numero.length),
                localidad:item.sect?.descripcion,
                castigo:item.castigo,
                flete_dolar:item.flete,
                precio:"$ "+this.ObjFunctions.formatNumberES(item.precio!,2),
                tipocambio:item.tpocmb,
                peso_neto:this.ObjFunctions.formatNumberES((item.pesoEntrada! - item.pesoSalida! - item.castigo!)*0.001,3),
                valor_compra:this.ObjFunctions.formatNumberES(item.precio!*item.tpocmb!*parseFloat(((item.pesoEntrada! - item.pesoSalida! - item.castigo!)*0.001).toFixed(3)),2),
                flete_soles:this.ObjFunctions.formatNumberES(item.flete!*item.tpocmbflete!*parseFloat(((item.pesoEntrada! - item.pesoSalida! - item.castigo!)*0.001).toFixed(3)),2),
                importe: this.ObjFunctions.formatNumberES(parseFloat((item.precio!*item.tpocmb!*parseFloat(((item.pesoEntrada! - item.pesoSalida! - item.castigo!)*0.001).toFixed(3))).toFixed(2))-
                    parseFloat((item.flete!*item.tpocmbflete!*parseFloat(((item.pesoEntrada! - item.pesoSalida! - item.castigo!)*0.001).toFixed(3))).toFixed(2)),2)
            };
        });
    }
    
    mapDataDescuento(data: Descuento[]): any {
        return data.map((item) => {
            return {
                fecha:this.convertFecha(item.fecReg!),
                tipoDescuento:item.tipoDescuento,
                descripcion:item.descripcion,
                importe:this.ObjFunctions.formatNumberES(item.importe!,2)
            };
        });
    }
}