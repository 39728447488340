<div fxLayout="column" fxLayoutAlign="center center" class="div_contenedor">    
    <mat-card fxLayout="column">
        <mat-card-title class="Titulo">Cambio de Clave</mat-card-title>   
        <form [formGroup]="FrmResetearPassword" >           
            <mat-card-content fxLayout="column">
                <div>
                    <mat-form-field class="Fila">
                        <textarea matInput cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize" placeholder="Usuario" formControlName="usuario" class="CampoTextArea" readonly></textarea>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="Fila">
                        <input matInput type="password" aria-label="string" placeholder="Clave Actual" formControlName="clave_old" autocomplete="off" maxlength="20" required>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="Fila">
                        <input matInput type="password" aria-label="string" placeholder="Clave Nueva" formControlName="clave" autocomplete="off" maxlength="20" required>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="Fila">
                        <input matInput type="password" aria-label="string" placeholder="Confirmar Clave" formControlName="clave_temp" autocomplete="off" maxlength="20" required>
                    </mat-form-field>
                </div>       
            </mat-card-content>    
        </form>                 
       <mat-card-actions style="text-align: center;">
            <button mat-button mat-raised-button class="btnLimpiar" (click)="Limpiar()">Limpiar</button>
            <button mat-button mat-raised-button class="btnGuardar" (click)="Guardar()">Actualizar</button> 
       </mat-card-actions>
   </mat-card> 
</div>
