import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AutenticaService } from 'src/app/@core/services/AutenticaService';
import { Proveedor } from 'src/app/@core/models/Proveedor';
import { ProveedorService } from 'src/app/@core/services/ProveedorService';
import Swal from 'sweetalert2';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import { Router } from '@angular/router';

@Component({
  selector: 'app-gestionar-proveedor',
  templateUrl: './gestionar-proveedor.component.html',
  styleUrls: ['./gestionar-proveedor.component.scss']
})
export class GestionarProveedorComponent implements OnInit {

  FrmGestProveedor!: FormGroup;

  codigo:number=0;

  Proveedor: Proveedor[]=[];
  displayedColumns: string[] = ['codigo','direccion','razonSocial','dni_ruc'];
  dataSource = this.Proveedor;

  ProveedorTable : Proveedor[] = [];
  ProveedorTableDataSource = new MatTableDataSource(this.ProveedorTable);

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  constructor(private _formBuilder: FormBuilder,
              private router: Router,
              private ObjAutenticaService: AutenticaService,
              private ObjProveedorService: ProveedorService) { }

  ngOnInit(): void {
    this.FrmGestProveedor=this._formBuilder.group({
      selectBuscar:['1'],
      txtFiltro:[''],
    });

    this.LlenaGrilla(0);
  }

  Buscar(){
    if (this.FrmGestProveedor.controls["txtFiltro"].value === null || 
      this.FrmGestProveedor.controls["txtFiltro"].value.length === 0 || 
      /^\s+$/.test(this.FrmGestProveedor.controls["txtFiltro"].value)) {
        Swal.fire({
          html:'Ingrese dato a consultar',
          icon:'info'
        })
    }
    else{
      this.LlenaGrilla(1);
    }
  }

  LlenaGrilla(opc: number){
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjProveedorService.ListaEmpresa(parseInt(this.FrmGestProveedor.controls["selectBuscar"].value),this.FrmGestProveedor.controls["txtFiltro"].value)
                .subscribe(data => {
                  if (data.isSuccess){
                    this.Proveedor= data.data;
                    this.ProveedorTableDataSource.data=this.Proveedor; 

                    if(opc===1 && this.Proveedor.length===0){
                      Swal.fire({
                        html:'No se encontraron datos con estos filtros',
                        icon:'info'
                      });
                    }
                  }                                         
                }); 

              this.ProveedorTableDataSource.paginator=this.paginator;
            }
            else{
                variablesGlobales.token='';
                variablesGlobales.usuario.codusu=0;
                this.router.navigate(['login']);
            }
          }
        },
        error => {
          console.log(error);
          variablesGlobales.token='';
          variablesGlobales.usuario.codusu=0;
          this.router.navigate(['login']);
        }   
      );   
  }

  Refrescar(){
    this.FrmGestProveedor.patchValue({
      selectBuscar:'1',
      txtFiltro:'',
    });

    this.LlenaGrilla(0);
  }

  fnSoloNumeros(event:any) {
    var tecla = (document.all) ? event.keyCode : event.which;
    if (tecla===8) return true;
    var patron =/[0-9]/;
    var te = String.fromCharCode(tecla);
    return patron.test(te);
  }

  fnIngNumDocumento(event:any){
    if(this.FrmGestProveedor.controls["selectBuscar"].value==="1"){
      return this.fnSoloNumeros(event);
    } 
    return true;
  }

  Limpiar(){
    this.FrmGestProveedor.controls["txtFiltro"].setValue("");
  }

  ResetearClave(id:number){
    Swal.fire({
      text: "¿Esta seguro de asignar nueva clave?",
      icon: 'question',
      showCancelButton: true,
      cancelButtonText:'Cancelar',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      reverseButtons: true
    }).then((result) => {
      if (result.value){
        this.ObjAutenticaService.ValidarApp()
          .subscribe(
            data => { 
              if(data.isSuccess){
                if(data.tx_token.length>0){
                  variablesGlobales.token=data.tx_token;

                  this.ObjProveedorService.EmpresaReset(id)
                    .subscribe(
                      data => {
                        if (data.isSuccess){
                          Swal.fire({
                            title: data.tx_Mensaje,
                            icon: 'success'});
                        }
                        else{
                          Swal.fire({
                            title: 'Error al procesar en BD',
                            icon: 'warning'}); 
                        }                                         
                      },
                      error => {
                        console.log(error);
                        Swal.fire({
                          title:'El servicio solicitado no se encuentra disponible',
                          icon: 'error',
                        });
                      }
                    );
                }
                else{
                    variablesGlobales.token='';
                    variablesGlobales.usuario.codusu=0;
                    this.router.navigate(['login']);
                }
              }
            },
            error => {
              console.log(error);
              variablesGlobales.token='';
              variablesGlobales.usuario.codusu=0;
              this.router.navigate(['login']);
            }   
          );
      }
    });
  }
}
