import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuracion } from './config';

@Injectable({
    providedIn: 'root'
})

export class DeudaService {
    
    constructor(private _http: HttpClient) { }

    Obtener(nombre_reporte: string, id: number){
        let bodyServicio = {"codptm":id};

        return this._http.post<any>(Configuracion.url1+nombre_reporte+ '/eecc', bodyServicio);
    }
}