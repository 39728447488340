import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import Swal from 'sweetalert2';
import { Proveedor } from '../@core/models/Proveedor';
import { AutenticaService } from '../@core/services/AutenticaService';
import { ProveedorService } from '../@core/services/ProveedorService';
import { variablesGlobales } from '../Util/variableGlobal';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy{

  fgLogin!: FormGroup;
  ObjProveedor!: Proveedor;
  captchaResponse!: string;

  constructor(private fb: FormBuilder, 
              private ObjAutenticaService: AutenticaService,
              private ObjProveedorService: ProveedorService,
              private router: Router,
              private recaptchaV3Service: ReCaptchaV3Service) { 
                this.CompruebaSesionActiva();
              }

  ngOnInit() {    
    this.CrearFormulario();    
  }

  ngAfterViewInit() {
    const element = document.getElementsByClassName('grecaptcha-badge')[0] as HTMLElement;
    if (element) {
      element.style.visibility = 'visible';
    }
  }

  ngOnDestroy() {
    const element = document.getElementsByClassName('grecaptcha-badge')[0] as HTMLElement;
    if (element) {
      element.style.visibility = 'hidden';
    }
  }

  CrearFormulario(): FormGroup {
    return this.fgLogin = this.fb.group({
      Login: [null,Validators.compose([Validators.required,
            Validators.maxLength(120)])],
      Clave : [null, Validators.compose([
            Validators.required,
            Validators.maxLength(20)])],
      //recaptchaReactive: [null, Validators.required]
     })
  }

  CompruebaSesionActiva(){    
    if(variablesGlobales.usuario.codusu!==0){            
      this.router.navigate(['/menu']);
    }
  }
   
  keyDownEnter(event:any) {
    if(event.keyCode == 13) {
      this.ValidarLogin();
    }
  }

  validarFormulario() {
    if ( !this.fgLogin.valid ) {
        return false;
    }
    else{       
        return true;
    }  
  }

  /*resolved(captchaResponse: string) {
    this.captchaResponse=captchaResponse; 
  }*/

  ValidarLogin(){
    const validacion = this.validarFormulario();
   
    if (!validacion) {
      return false;
    }

    this.recaptchaV3Service.execute('login')
      .subscribe(token => {
        //console.log(`Token [${token}] generated`);
        this.ObjAutenticaService.ValidarApp()
          .subscribe(
            data => { 
              if(data.isSuccess){
                if(data.tx_token.length>0){
                  variablesGlobales.token=data.tx_token;

                  this.ObjProveedorService.ValidarEmpresa(this.fgLogin.controls.Login.value,this.fgLogin.controls.Clave.value)
                    .subscribe(
                      data => {
                        if(data.isSuccess){
                          this.ObjProveedor=data.data;
                          variablesGlobales.usuario.codusu=this.ObjProveedor.codigo;
                          variablesGlobales.usuario.numdoc=this.ObjProveedor.dni_ruc!;
                          variablesGlobales.usuario.nombre=this.ObjProveedor.razonSocial!;
                          variablesGlobales.usuario.codrol=(this.ObjProveedor.codigo===-1)?1:2;
                          variablesGlobales.usuario.rol=(this.ObjProveedor.codigo<0)?"Administrador":"Consultor";

                          this.router.navigate(['/menu/']);
                        }
                        else{
                          this.limpiar();
                          Swal.fire(
                            'Advertencia',
                            'Atención: Usuario o Contraseña incorrecta',
                            'warning'
                          );
                        }          
                      },
                      error => {
                        console.log(error);
                        Swal.fire(
                          'Error',
                          'El servicio solicitado no se encuentra disponible',
                          'error'
                        );
                      }
                    );
                }
                else{
                  Swal.fire(
                    'Advertencia',
                    'Autentificación incorrecta a la aplicación',
                    'warning'
                  );
                }
              }
            },
            error => {
              console.log(error);
              Swal.fire(
                'Error',
                'El servicio de autentificación no se encuentra disponible',
                'error'
              );
            }     
          );
      });

      return true;
  }

  limpiar(){    
    //this.fgLogin.controls.recaptchaReactive.setValue(null);
    this.fgLogin.controls["Login"].setValue(null);
    this.fgLogin.controls["Clave"].setValue("");
  }

}
