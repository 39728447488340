import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuComponent } from './menu.component';
import { PaginatePipe } from '../Util/pipes/paginate.pipe';
import { MenuRoutingModule } from './menu-routing.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule} from '@angular/material/progress-bar';
import { MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatAutocompleteModule} from '@angular/material/autocomplete';
import { MatRadioModule} from '@angular/material/radio';
import { MatMenuModule} from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatTabsModule} from '@angular/material/tabs';
import { ConsultarDocumentoComponent } from './consultar-documento/consultar-documento.component';
import { GestionarProveedorComponent } from './gestionar-proveedor/gestionar-proveedor.component';
import { InicioComponent } from './inicio/inicio.component';
import { ActualizaClaveComponent } from './actualiza-clave/actualiza-clave.component';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { RecordEntregaComponent } from './record-entrega/record-entrega.component';

const PAGES_COMPONENTS = [
  MenuComponent,
  PaginatePipe,
];

@NgModule({
  imports: [
    MatProgressBarModule,
    MatProgressSpinnerModule,
    CommonModule,    
    MenuRoutingModule, 
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatIconModule, 
    MatTableModule,
    MatCardModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    MatButtonModule,
    MatInputModule,
    MatAutocompleteModule,    
    MatFormFieldModule,    
    MatPaginatorModule,
    MatRadioModule,
    MatDialogModule,
    MatMenuModule,  
    MatCheckboxModule,
    MatTabsModule,
    MomentDateModule
  ],
  declarations: [
    ...PAGES_COMPONENTS,
    ConsultarDocumentoComponent,
    GestionarProveedorComponent,
    InicioComponent,
    ActualizaClaveComponent,
    RecordEntregaComponent
  ],
  entryComponents: [
  ],
})
export class MenuModule { }
