import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuracion } from './config';
import { variablesGlobales } from 'src/app/Util/variableGlobal';

@Injectable({
    providedIn: 'root'
})

export class ProveedorService {
    public url:string = Configuracion.url1 + 'Proveedor/';
    
    constructor(private _http: HttpClient) { }

    ValidarEmpresa(login: string, clave: string){
        let bodyServicio = {"TX_LOGIN":login,
                            "TX_CLAVE":clave};

        return this._http.post<any>(this.url + 'ValidarEmpresa', bodyServicio);
    }

    ListaEmpresa(opc: number, dato: string){
        let bodyServicio = {"NU_OPCION_BUSQUEDA":opc,
                            "TX_DATO_BUSQUEDA":dato};

        return this._http.post<any>(this.url + 'ListaEmpresa', bodyServicio);
    }

    EmpresaReset(id: number){
        let bodyServicio = {"codigo":id,
                            "codusu":variablesGlobales.usuario.codusu};

        return this._http.post<any>(this.url + 'EmpresaReset', bodyServicio);
    }

    EmpresaCambiarClave(clave: string, clave_old: string){
        let bodyServicio = {"codigo":variablesGlobales.usuario.codusu,
                            "TX_CLAVE":clave,
                            "TX_CLAVE_OLD":clave_old};

        return this._http.post<any>(this.url + 'EmpresaCambiarClave', bodyServicio);
    }
}