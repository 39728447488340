import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuracion } from './config';

@Injectable({
    providedIn: 'root'
})

export class TicketService {
    public url:string = Configuracion.url1 + 'Ticket/';
    
    constructor(private _http: HttpClient) { }

    Obtener(id: number){
        let bodyServicio = {"Codtck":id};

        return this._http.post<any>(this.url + 'DocTicket', bodyServicio);
    }
}