import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuracion } from './config';
import { variablesGlobales } from 'src/app/Util/variableGlobal';

@Injectable({
    providedIn: 'root'
})

export class ReporteService {
    public url:string = Configuracion.url1 + 'Reporte/';
    
    constructor(private _http: HttpClient) { }

    Obtener(codreporte: number, fechaini: string, fechafin:string){
        let bodyServicio = {"nU_OPCION_BUSQUEDA":codreporte,
                            "fE_FECHA_INICIO_BUSQUEDA":fechaini,
                            "fE_FECHA_TERMINO_BUSQUEDA":fechafin,
                            "nU_CODIGO_BUSQUEDA":variablesGlobales.usuario.codusu};

        return this._http.post<any>(this.url + 'ResumenTicketProveedor', bodyServicio);
    }
}