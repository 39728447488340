import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { NavigationEnd, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { variablesGlobales } from '../Util/variableGlobal';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  Menu1:any
  Menu2:any;
  codusu: number=0;
  usuarioname!: string;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map((result:any) => result.matches),
      shareReplay()
    );

  constructor(private router:Router,
              private breakpointObserver: BreakpointObserver) {
                if(variablesGlobales.usuario.codusu === 0){
                  this.router.navigate(['/login']);
                }
                else{
                  this.codusu = variablesGlobales.usuario.codusu;
                  this.usuarioname = "Usuario: "+variablesGlobales.usuario.nombre+", Rol: "+variablesGlobales.usuario.rol;
                  
                  this.router.routeReuseStrategy.shouldReuseRoute=function(){
                    return false;
                  }
        
                  this.router.events.subscribe((evt)=>{
                    if(evt instanceof NavigationEnd){
                      this.router.navigated=false;
                      window.scrollTo(0,0);
                    }
                  });
                }
              }

  ngOnInit() {
    this.ObtenerMenu();
  }
    
  ObtenerMenu(){    

    if(this.codusu<0){
      this.Menu1=[ 
        {codigo:1, name:"Gestiona Proveedor", route:"GestionarProveedor",icon:"",ord:1},
        {codigo:2, name:"Cambiar Clave", route:"ActualizaClave",icon:"",ord:2}           
      ];
    }
    else{
      this.Menu1=[ 
        {codigo:1, name:"Consultar Documento", route:"ConsultarDocumento",icon:"",ord:1},
        {codigo:2, name:"Reportes", route:"RecordEntrega",icon:"",ord:2},
        {codigo:3, name:"Cambiar Clave", route:"ActualizaClave",icon:"",ord:3}       
      ];
    }

    this.Menu2={codigo:11, name:"Cerrar Sesión", route:"",icon:"",ord:11};
  }

  CerrarSesion(){
    Swal.fire({
      text: "¿Está seguro de cerrar?",
      icon: 'question',
      showCancelButton: true,
      cancelButtonText:'No',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí',
      reverseButtons: true
    }).then((result) => {
      if (result.value){
        variablesGlobales.token='';
        variablesGlobales.usuario.codusu=0;
        this.router.navigate(['login']);
      }
    }) 
  }

}
