import { Injectable } from '@angular/core';
import { ImageToBase64 } from '../helpers/ImageToBase64';
import { Functions } from '../helpers/Functions';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import { Descuento } from '../models/Descuento';
import { Deuda } from '../models/Deuda';

import * as pdfMake from "pdfmake/build/pdfmake.min.js";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

@Injectable({
    providedIn: 'root'
})

export class RptPDFDeuda {

    total:number=0;

    constructor(private canvas: ImageToBase64,
                private ObjFunctions: Functions) { }

    convertFecha(fecha: string): string{
        var parts=fecha.split("-");
        var anio =parts[0];
        var mes =parts[1];
        var dia =parts[2].substring(0,2);
    
        return dia+"/"+mes+"/"+anio;
    }

    /** Diseño de cabecera **/
    setTitle(logo: any, codigo: number, num_pagina: number): any {
        //Margen de la cabecera [left,top,right,bottom]
        let margin = [25, 25, 25, 15];

        let fontSizeTitle=16;
        let fontSizePrint=8;

        //Dimensiones del logo
        let widthLogo=100;
        let heightLogo=38.38;

        return [
            {   margin:margin,
                table: {
                    widths:['*'],
                    headerRows: 1,
                    body:[
                        [
                            {
                                table:{
                                    widths:['19%','*','25.5%'],
                                    body:[
                                        [
                                            //logo de la empresa
                                            {
                                                image: logo,
                                                width: widthLogo,
                                                height: heightLogo,
                                                rowSpan:3
                                            },
                                            //Título del documento
                                            {
                                                text: (codigo>0)?"EE.CC DE ANTICIPOS":"EE.CC VENTA - RFF",
                                                fontSize: fontSizeTitle,
                                                bold: true,
                                                alignment : 'center',
                                                rowSpan:3,
                                                margin:[0,11,0,0]
                                            },
                                            //Datos de impresión
                                            {
                                                text: [
                                                    { text: 'Impreso: ', bold: true},
                                                    { text: variablesGlobales.usuario.numdoc+' - '+this.ObjFunctions.GetDate().substring(0,10)}
                                                ],
                                                fontSize: fontSizePrint,
                                                alignment : 'right'
                                            }
                                        ],
                                        [
                                            {}, {}, {text: this.ObjFunctions.GetDate().substring(11,this.ObjFunctions.GetDate().length), fontSize: fontSizePrint, alignment : 'right'}
                                        ],
                                        [
                                            {}, {}, {text: "PÁG: "+num_pagina, fontSize: 9, alignment : 'right'},
                                        ]
                                    ]
                                },
                                layout:'noBorders'
                            },
                        ],
                        [{}]
                    ]
                },
                layout: 'headerLineOnly'
            }
        ];
    }

    /** Método para crear el contenido de tabla **/
    createTableBody(data: any[]): any {
        const body:any = [];

        data.forEach((record:any) => {
            const row = [];

            for (const key in record) {
                if(key=="numero" || key=="documento"){
                    row.push({ text: record[key], style: 'tableContent', border: [false,false,false,false] });
                }
                else{
                    row.push({ text: record[key], style: 'tableContent', border: [false,false,false,false], alignment: 'right' });
                }
            }

            body.push(row);
        });

        return body;
    }

    SubTotal(data: Descuento[]){
        this.total=0;

        for(let item of data){
            this.total += parseFloat(item.importe!.toFixed(2));
        }
    }

    /** Definición del documento PDF **/
    getDocDefinition(logo: any, obj: Deuda, codigo: number, orientation: string, pageSize: string): any {
        this.SubTotal(obj.detalleDescuento!);

        let pageMargin=[25, 80, 25, 55];
        let fontSizeBody=9;
        let headerFontTable=9;
        let bodyFontTable=9;
    
        const docDefinition = {
            pageOrientation: orientation,
            pageSize,
            pageMargins: pageMargin,
            header: (currentPage: number, pageCount: number) => {
                return this.setTitle(logo, codigo, currentPage);
            },
            content: [
                {
                    table: {
                        widths: ['9%', '*', '9%', '9%', '9%'],
                        body: [
                            [
                                {text:'Proveedor:', fontSize: fontSizeBody, bold: true, border:[false,false,false,true]},
                                {text:obj.objPrv?.dni_ruc+'\t '+obj.objPrv?.razonSocial, fontSize: fontSizeBody, border:[false,false,false,true]},
                                {text:'Adelanto', fontSize: fontSizeBody, bold: true, border:[false,false,false,true], alignment: 'right' },
                                {text:'Devuelto', fontSize: fontSizeBody, bold: true, border:[false,false,false,true], alignment: 'right'},
                                {text:'Saldo', fontSize: fontSizeBody, bold: true, border:[false,false,false,true], alignment: 'right'},
                            ]
                        ]
                    },
                },
                {
                    table: {
                        widths: ['2%', '7%', '12.7%', '10.4%', '*', '7.5%', '9.7%', '9.7%', '9.7%'],
                        body: [
                            [
                                {text:'', border:[false,false,false,false]},
                                {text:'Venta :', fontSize: fontSizeBody, bold: true, border:[false,false,false,true]},
                                {text:'PALMICULTOR', fontSize: fontSizeBody, border:[false,false,false,true]},
                                {text:(codigo>0)?this.convertFecha(obj.objTs?.fecreg!):this.convertFecha(obj.fecReg!), fontSize: fontSizeBody, border:[false,false,false,true]},
                                {
                                    text:((codigo>0)?('TS Nro: '+obj.objTs?.nro_TS+' - '+obj.objTs?.objMedioPago?.abrev):
                                        (obj.objTipDoc?.abrev+' Nro: '+obj.nroDoc)), 
                                    fontSize: fontSizeBody,
                                    border:[false,false,false,true]
                                },
                                {
                                    text: [
                                        { text: 'Nro: ', bold: true},
                                        obj.cuota
                                    ],
                                    fontSize: fontSizeBody,
                                    border:[false,false,false,true],
                                    alignment: 'right'
                                },
                                {text:this.ObjFunctions.formatNumberES(obj.importe!,2), fontSize: fontSizeBody, border:[false,false,false,true], alignment: 'right'},
                                {text:this.ObjFunctions.formatNumberES(this.total,2), fontSize: fontSizeBody, border:[false,false,false,true], alignment: 'right'},
                                {text:this.ObjFunctions.formatNumberES(obj.importe! - parseFloat(this.total.toFixed(2)),2), fontSize: fontSizeBody, border:[false,false,false,true], alignment: 'right'},
                            ]
                        ]
                    }
                },
                {
                    table: {
                        widths: ['24%', '12.4%', '*', '9.2%', '9.2%', '9.2%'],
                        body: this.createTableBody(this.mapData(obj.detalleDescuento!))
                    }
                },
                {
                    table: {
                        widths: ['*', '8.9%', '8.9%', '8.9%'],
                        body: [
                            [
                                {
                                    text:'Sub Total', 
                                    fontSize: fontSizeBody, 
                                    bold: true, border:[false,false,false,false], 
                                    alignment: 'right',
                                    margin:[0,0,0,15]
                                },
                                {
                                    text:this.ObjFunctions.formatNumberES(obj.importe!,2), 
                                    fontSize: fontSizeBody,
                                    bold: true, 
                                    border:[false,true,false,false],
                                    alignment: 'right',
                                    margin:[0,0,0,15]
                                },   
                                {
                                    text:this.ObjFunctions.formatNumberES(this.total,2),
                                    fontSize: fontSizeBody, 
                                    bold: true, 
                                    border:[false,true,false,false],
                                    alignment: 'right',
                                    margin:[0,0,0,15]
                                },
                                {
                                    text:this.ObjFunctions.formatNumberES(obj.importe! - parseFloat(this.total.toFixed(2)),2),
                                    fontSize: fontSizeBody, 
                                    bold: true, 
                                    border:[false,true,false,false],
                                    alignment: 'right',
                                    margin:[0,0,0,15]
                                }
                            ],
                            [
                                {
                                    text:'Total', 
                                    fontSize: fontSizeBody,
                                    bold: true, 
                                    border:[false,false,false,false], 
                                    alignment: 'right',
                                    margin:[0,0,0,10]
                                },
                                {
                                    text:this.ObjFunctions.formatNumberES(obj.importe!,2), 
                                    fontSize: fontSizeBody,
                                    bold: true, 
                                    border:[false,true,false,true],
                                    alignment: 'right',
                                    margin:[0,0,0,10]
                                },
                                {
                                    text:this.ObjFunctions.formatNumberES(this.total,2),
                                    fontSize: fontSizeBody, 
                                    bold: true, 
                                    border:[false,true,false,true],
                                    alignment: 'right',
                                    margin:[0,0,0,10]
                                },
                                {
                                    text:this.ObjFunctions.formatNumberES(obj.importe! - parseFloat(this.total.toFixed(2)),2),
                                    fontSize: fontSizeBody, 
                                    bold: true, 
                                    border:[false,true,false,true],
                                    alignment: 'right',
                                    margin:[0,0,0,10]
                                }
                            ],
                            [
                                { text: '', border: [false,false,false,false]},
                                { text: '', border: [false,false,false,true], colSpan:3},  
                                {}, 
                                {}                       
                            ]
                        ]
                    }
                }
            ],
            styles: {
                tableHeader: {
                    fontSize: headerFontTable,
                    bold: true
                },
                tableContent: {
                    fontSize: bodyFontTable
                }
            }
        };

        return docDefinition;
    }

    generatePdf(docDefinition:any): void {
        const pdfObject = pdfMake.createPdf(docDefinition);
        pdfObject.open();
    }

    getPdfData(obj: Deuda, codigo: number): void {
        //Se obtiene la trama de la imagen en assets que se va a incorporar en el documento
        this.canvas.getBase64Image('./assets/img/Olamsa.png')
        .then(base64Img => {
            const logo = base64Img;
            this.generatePdf(this.getDocDefinition(logo, obj, codigo, 'portrait', 'A4'));
        });

    }

    mapData(data: Descuento[]): any {
        return data.map((item) => {
            return {
                fecha:this.convertFecha(item.fecReg!),
                numero:item.numero,
                documento:"BOLETA: "+item.objBoleta?.numero+", TS: "+item.objBoleta?.objTS?.nro_TS,
                adelanto:"",
                devuelto:this.ObjFunctions.formatNumberES(item.importe!,2),
                saldo:""
            };
        });
    }
}